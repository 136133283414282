<template>
  <teleport to="#modals">
    <div class="modal is-active is-layout-fixed" v-if="isActive" @keydown.esc=answerNAY tabindex="0" ref="modalroot">
      <div class="modal-background"></div>
      <div class="modal-card" :style="widthStyle">
        <header class="modal-card-head">
          <img src="../../assets/img/Fiskars_logo_Orange_RGB@2x.png" width="112" height="28" class="logo">
          <p class="modal-card-title title has-text-centered mb-0" style="margin-top:15px;">{{ headingText }}</p>
          <div v-if="hideX" class="buttons is-right" style="margin-top:15px;">
            <button class="button is-secondary is-inverted" @click="answerNAY">
              <span class="icon is-medium">
                <i class="fas fa-2x fa-window-close"></i>
              </span>
            </button>
          </div>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <slot></slot>
        </section>
        <footer class="modal-card-foot" v-if="hasButtons">
          <div class="buttons">
            <button v-if="showOk" class="button fiskars-white-button mr-3" @click="answerOK">{{ okButtonText }}</button>
            <button v-if="showCancel" class="button fiskars-button" @click="answerNAY">{{ cancelButtonText }}</button>
          </div>
        </footer>
      </div>
    </div>
  </teleport>
</template>
<script>
import { FeColorMatrixElement } from 'canvg'
export default {
  name: 'ModalDialog',
  props: {
    showOk: {
      type: Boolean,
      default: true,
    },
    hiddenX: {
      type: Boolean,
      default: false,
    },    
    okButtonText: {
      type: String,
      default: 'Kyllä',
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
    cancelButtonText: {
      type: String,
      default: 'Peruuta',
    },
    headingText: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '640px'
    },
    minWidth: {
      type: String,
      default: '640px'
    }
  },
  emits: ['modal-result-ok', 'modal-result-cancel'],
  data() {
    return {
      isActive: false,
      resolve: null,
      reject: null,
    }
  },
  computed: {
    hasButtons() {
      return this.showOk || this.showCancel
    },
    widthStyle() {
      return {
        width: this.width,
        'min-width': this.minWidth
      }
    }
  },
  methods: {
    showModal() {
      document.documentElement.style.overflow = 'hidden'
      // promise based function to show dialog
      this.isActive = true
      this.$nextTick().then(() => {
        this.$refs.modalroot.focus()
      })
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    answerOK(...args) {
      document.documentElement.style.overflow = 'auto'
      this.$emit('modal-result-ok')
      this.isActive = false
      if (this.resolve != null) {
        return this.resolve(...args)
      }
    },
    answerNAY(...args) {
      document.documentElement.style.overflow = 'auto'
      this.$emit('modal-result-cancel')
      this.isActive = false
      if (this.reject != null) {
        return this.reject(...args)
      }
    },
  },
}
</script>
<style scoped lang="scss">
@import "../../assets/sass/mybulma";
.button.is-secondary.is-inverted:hover {
    background-color: initial;
}

.is-layout-fixed {
    position: fixed !important;
}

.modal {
  z-index: 100;
  .modal-card {
    @media (max-width: 500px) {
      min-width: 0px !important;
      width: 100% !important;
    }
  }
}

.logo {
  margin-top: 15px;
  margin-left: 15px;
}

.modal-card-title {
  margin-top: 20px !important;
}


.modal-card-body {
  padding-top: 50px;
  padding-bottom: 50px;
}

.buttons {
  margin-left: 15px;
  .button {
    min-width: 100px;
    text-align: center;
    display: block;
  }
}

.modal-card-head {
  padding: 0px;
  border-radius: 0px;
  background-color: #FFF;
  border-bottom: none;
  .button {
    color: #0D0D0C;
  }
}
.modal-card-foot {
  padding: 5px;
  border-radius: 0px;
  background-color: #FFF;
  border-top: none;
  padding-bottom: 10px;
  @media (max-width: 768px) {
    .buttons {
      margin-right: auto;
      margin-left: auto;
      .button {
        max-width: 250px;
      }
    }
  }
}
</style>
