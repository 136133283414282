<template>
  <section class="hero cookie-policy-page is-fullheight">
    <FiskarsNavbar />
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column">
            <h1 class="mb-5">Cookie Declaration</h1>

            <p class="font-16 mb-4">Better services with cookies.</p>
            <p class="font-16">
              We use cookies to provide you a better user experience and to make
              our services even better.
            </p>
            <div class="content">
              <ul>
                <li>
                  Essential cookies help us to activate basic functions such as
                  the shopping cart.
                </li>
                <li>
                  Analytics cookies collect data and help us to develop our site
                  and service.
                </li>
              </ul>
            </div>
            <p class="font-16 mb-4">Give your consent to determine how your collected data can be utilised.</p>
            <p class="font-16 mb-4">This Cookie policy informs you how PackageMedia Oy uses Cookies, web beacons and other similar technologies (jointly referred to as “Cookies”) in its websites and how you can manage and control the use of Cookies on your computer or device.</p>
            <p class="font-16 mb-4">In order to provide you with an online experience with personalized features and content, you will need to allow your computer, tablet or mobile device to accept cookies.</p>
            <p class="font-16 mb-4">Should you have any questions related to our Cookie Policy, you are welcome to contact customfiskars@packagemedia.fi</p>

            <p class="has-text-weight-bold is-underlined">What are Cookies?</p>
            <p class="font-16 mb-4">Cookies are small files, typically letters and numbers, placed on your computer or device by a web server when you access certain websites. Cookies are stored on your computer’s hard drive or other terminal equipment when you browse our sites.</p>

            <p class="has-text-weight-bold is-underlined mb-2">What type of Cookies do we use and why are they used?</p>
            <p class="font-16 mb-2"><span class="has-text-weight-bold">Functional Cookies:</span> We use functional Cookies to ensure the functionality of our website. These Cookies enable the website to remember your login information and remembers other selections done by you during your visit on our website (such as, the things you put in your shopping cart or for the site’s video content to function properly).</p>
            <p class="font-16 mb-2"><span class="has-text-weight-bold">Preference Cookies:</span> Preference cookies enable the website to remember information that changes the way the website behaves or looks, such as your language settings or location.</p>
            <p class="font-16 mb-2"><span class="has-text-weight-bold">Performance Cookies:</span> We also want to develop our platform, our online stores and other services we offer to you. This is why we use performance Cookies on our websites. These Cookies collect information which we use for reporting and analytics, such as how you use our website and how often you visit the website.</p>
            <p class="font-16 mb-2"><span class="has-text-weight-bold">Marketing Cookies:</span> We use marketing Cookies on our website. These Cookies help us to tailor marketing to you and your interests and provide you with a more personalized service in the future. Without these Cookies, the advertisements that you see may be less relevant and interesting to you. These Cookies remember that you visited our website, and we may share this information with third parties, such as advertisers so that you may see our advertisements on other sites as well. In the section 4 below, there is information on the third party recipients, with whom we share this data.</p>
          </div>
        </div>
      </div>
    </div>
    <FiskarsFooter />
  </section>
</template>

<script>
import FiskarsNavbar from "@/components/FiskarsNavbar.vue";
import FiskarsFooter from "@/components/FiskarsFooter.vue";

export default {
  name: "CookiePolicy",
  components: {
    FiskarsNavbar,
    FiskarsFooter,
  },
  setup(props) {},
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
</style>
