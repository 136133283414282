<template>
  <section class="hero is-fullheight">
    <FiskarsNavbar />
    <div class="hero-body pt-5">
      <div class="container">
        <FiskarsEditor />
      </div>
    </div>
    <FiskarsFooter />
  </section>
</template>

<script>
import FiskarsNavbar from "@/components/FiskarsNavbar.vue";
import FiskarsFooter from "@/components/FiskarsFooter.vue";
import FiskarsEditor from "@/components/FiskarsEditor.vue";
import { useStore } from "vuex";
import { reactive, ref } from "vue";
import { useRoute } from "vue-router/dist/vue-router";

export default {
  name: "CartPage",
  components: {
    FiskarsEditor,
    FiskarsNavbar,
    FiskarsFooter,
  },
  setup() {
    // Variables
    const product = reactive({});
    const item = reactive({})
    const store = useStore();
    const route = useRoute();

    Object.assign(
      product,
      store.getters.getProductById(route.params.productId)
    );

    Object.assign(
      item,
      store.getters.getItemById(route.params.itemId)
    );

    return {
      product,
      item
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
</style>
