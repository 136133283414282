<template>
  <li class="list-item is-flex w-100">
    <div class="list-content padding-zero">
      <div
        class="img-container is-clickable is-hidden-touch"
        @click="goToProductDetail"
      >
        <img
          src="../assets/img/scissors_box1.jpg"
          class="bottom-img-product-mobile"
        />
        <img
          src="../assets/img/product_766.png"
          class="top-img-product-mobile"
        />
      </div>

      <div id="product-carousel" class="carousel is-hidden-desktop">
        <div class="item-1 has-text-centered">
          <img
            :src="product.thumbnail"
            class="product-img"
            @click="goToProductDetail"
          />
        </div>
        <div class="item-2 has-text-centered">
          <img
            src="../assets/img/scissors_box1.jpg"
            class="product-img"
            @click="goToProductDetail"
          />
        </div>
      </div>

      <p class="product-family has-text-left mb-1">
        {{ product.family }}
      </p>
      <p class="product-name has-text-left flexible py-1">
        {{ product.name }},
      </p>
      <p class="product-price has-text-left mt-1">
        {{ (product.price / 100).toFixed(2).replace(".", ",") }}€
      </p>
      <button
        class="button fiskars-button design-package-button mt-4"
        @click="selectProduct(product.id)"
      >
        Suunnittele lahjapakkaus
      </button>
    </div>
  </li>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, ref, computed, onMounted } from "vue";
import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel.min.js";

import selectProductMixin from "@/components/mixins/addProduct.js";

export default {
  name: "ProductCard",
  props: {
    product: Object,
  },
  components: {},
  setup(props) {
    const router = useRouter();
    const { selectProduct } = selectProductMixin();
    const product = reactive(props.product);

    const initCarousel = () => {
      const bulmaCarouselElement = bulmaCarousel.attach("#product-carousel", {
        navigation: false, // show arrows
      });
    };

    const goToProductDetail = () => {
      router.push({
        name: "ProductDetail",
        params: {
          productId: product.id,
        },
      });
    };

    onMounted(() => {
      initCarousel();
    });
    return {
      goToProductDetail,
      selectProduct,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.product-family {
  font-size: 13px;
  color: #666666;
}

.product-name {
  font-size: 16px;
  color: #0d0d0c;
}

.product-price {
  font-size: 20px;
  font-family: helvetica-now-display-xbd;
  color: #0d0d0c;
}

.design-package-button {
  width: 100%;
}

.list-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1em;
  width: 100%;
}

.flexible {
  flex: 1 0 auto;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #e9e9e9;
  border-radius: 50%;
  display: block;
  &.active {
    background-color: #c1d1a6;
  }
}

.img-container {
  position: relative;
  width: 280px;
  height: 316px;
  margin: 0 auto;
  img {
    position: absolute;
    left: 0;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
  }
  .top-img-product-mobile:hover {
    opacity: 0;
  }
}

.padding-zero {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}
</style>
