<template>
  <div>

    <div class="columns">

      <div class="column is-flex border-2" :class="{ 'border-no-editable': !isEditable }">
        <span v-if="isEditable" class="minus-icon is-clickable" @click="decreaseAmount"></span>
        <span class="mx-5">
          <input class="amount-input" type="number" v-model="amount" @input="onInputChange" :disabled="!isEditable">
        </span>
        <span v-if="isEditable" class="plus-icon is-clickable" @click="increaseAmount"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {

  name: 'ProductAmount',
  props: {
    modelValue: Number,
    isEditable: Boolean,
    itemId: String
  },
  setup(props, { emit }) {
    // Variables
    const amount = ref(props.modelValue)

    const increaseAmount = () => {
      amount.value += 1
      emit("amountupdated", props.itemId, amount.value)
      emit('update:modelValue', amount.value)
    }

    const decreaseAmount = () => {
      if(amount.value == 0) return;
      amount.value -= 1
      emit("amountupdated", props.itemId, amount.value)
      emit('update:modelValue', amount.value)
    }

    const onInputChange = () => {
      emit("amountupdated", props.itemId, amount.value)
      emit('update:modelValue', amount.value)
    }

    return {
      amount,
      increaseAmount,
      decreaseAmount,
      onInputChange,
     };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.minus-icon {
  background: url('../assets/icons/icon_minus.svg');
  height: 20px;
  width: 20px;
  display: block;
}
.plus-icon {
  background: url('../assets/icons/icon_plus.svg');
  height: 20px;
  width: 20px;
  display: block;
}

.border-2 {
  border: 2px solid #666666;
}

.border-no-editable {
  border-color: #666666 !important;
  background-color: #f5f5f5 !important;
  color: #666666 !important;
}

.amount-input {
  width: 50px;
  border: none;
  text-align: center;
  &:focus {
    outline:none;
  }  
}
/* Chrome, Safari, Edge, Opera */
.amount-input::-webkit-outer-spin-button,
.amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.amount-input[type=number] {
  -moz-appearance: textfield;
}  
</style>