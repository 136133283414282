<template>
  <div class="hero-foot mt-5">
    <footer class="footer">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-2">
            <h3 class="font-16">Asiakaspalvelu</h3>
            <a class="font-13" @click="goToCustomerService(false)">Ota yhteyttä</a>
            <a class="font-13" @click="goToCustomerService(true)">Usein kysyttyä</a>
          </div>
          <div class="column is-2">
            <h3 class="font-16">Säännöt</h3>
            <a href="https://fiskarsgroup.com/fi/fiskars-groupin-tietosuojakaytannot/tietosuojakaytanto-kuluttajille/" target="_blank" class="font-13">Tietosuojakäytäntö</a>
            <a href="https://fiskars-webshop-prod.s3.eu-north-1.amazonaws.com/public/Fiskars+B2B+yleiset+toimitusehdot+2024.pdf" target="_blank" class="font-13">Sopimus- ja käyttöehdot</a>
          </div>
          <div class="column is-2 is-offset-6">
            <img src="../assets/img/Fiskars_Group_B2B-white.png">
          </div>
          <div class="column is-12">
            <p class="copyright-text font-13">© Copyright Fiskars Group. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>

import {useRouter} from 'vue-router';

export default {
  name: "FiskarsFooter",
  setup() {
    const router = useRouter()

    const goToCustomerService = (scrollToFaq) => {
      if(scrollToFaq) {
        router.push({
        name: 'CustomerService',
        hash: '#faq'
      })
      } else {
        router.push({
        name: 'CustomerService'
      })        
      }

    }
    return {
      goToCustomerService,
    }
  }
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.footer {
  height: 100%;
  padding: 3rem 1.5rem 0rem;
  background-color: #000000;
}

h3 {
  font-family: helvetica-now-display-xbd;
  color: white;
  margin-bottom: 1rem;
  display: block;
  font-size: 16px;
}

a {
  color: white;
  font-family: helvetica-now-display-md;
  display: block;
  margin-bottom: 0.5rem;
  text-decoration: none;
}

a:hover {
  cursor: pointer;
  color: white;
}

.copyright-text {
  margin-top: 80px;
  color: #BFB7AC;
}
</style>
