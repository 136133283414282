import { useStore } from "vuex";

export default function cartMixin() {
  const store = useStore();


  function getProductName(item) {
    return store.getters.getProductById(item.product).name
  }  

  function getItemImageUrl(item) {
    if (item.thumbnail_url) return item.thumbnail_url;
    return store.getters.getProductById(item.product).thumbnail
  }

  function getPricePerProduct(item) {
    return ((store.getters.getProductById(item.product).price) / 100).toFixed(2).replace('.', ',')
  }

  function getTotalPricePerProduct(item) {
    return ((item.amount * store.getters.getProductById(item.product).price) / 100).toFixed(2).replace('.', ',')
  }

  function getBackDesignColor(item) {
    let backSrc = [
      "back_1_beetroot.png",
      "back_2_green.png",
      "back_3_terracotta.png",
      "back_4_black25.png"
    ]
    return backSrc[item.design - 1]
  }

  function getFrontDesignColor(item) {
    let backSrc = [
      "front_1_beetroot.png",
      "front_2_green.png",
      "front_3_terracotta.png",
      "front_4_black25.png"
    ]
    return backSrc[item.design - 1]
  }

  return {
    getItemImageUrl,
    getPricePerProduct,
    getTotalPricePerProduct,
    getProductName,
    getFrontDesignColor,
    getBackDesignColor
  };
}
