<template>
  <section class="hero cart-page is-fullheight">
    <FiskarsNavbar />

    <div class="hero-body pt-5">
      <div class="container">
        <div class="columns">
          <div class="column is-8">
            <h2>Ostoskorisi</h2>
            <p class="my-4">Tarkistathan, että tekstit ovat oikein. Voit vielä muokata pakkausta painamalla kynä-ikonia tuotteen kohdalla.</p>
          </div>
          <div
            class="column is-4 has-text-right-tablet has-text-centered-mobile"
            v-if="sendingType =='FREIGHT'"
          >
            <button
              class="button fiskars-button add-product-button"
              @click="addNewProduct"
            >
              Lisää uusi tuote
            </button>
          </div>
        </div>


        <ProductsTable></ProductsTable>

        <!-- Start Voucher form -->
        <!-- <div class="columns">
          <div class="column is-6">
            <p class="mb-4">Lisää alennuskoodi</p>
            <div class="field is-grouped">
              <p class="control is-expanded">
                <input
                  class="input"
                  type="text"
                  placeholder="Voucher"
                  v-model="voucherCode"
                />
                <span class="control is-expanded is-small has-text-danger">
                  {{ voucherCodeError }}
                </span>
              </p>
              <p class="control">
                <a
                  class="button fiskars-white-button-outlined"
                  @click="addVoucher"
                >
                  Aktivoi
                </a>
              </p>
            </div>
          </div>
        </div> -->
        <!-- End Voucher form -->
        <div class="columns" v-if="minAmountMsgError">
          <div class="column">
            <p class="has-text-danger has-text-left">Min amount 50</p>
          </div>
        </div>
        <div class="columns is-multiline">   
          <div class="column is-hidden-mobile">
            <button
              class="button fiskars-button go-back-button"
              @click="goBack"
            >
              Palaa takaisin
            </button>
            <button
              class="button ml-3 fiskars-button go-contact-button"
              @click="goContact"
            >
              Kassalle
            </button>
          </div>
          <div class="column is-flex is-flex-direction-column is-hidden-tablet">
            <button
              class="button fiskars-button go-contact-button"
              @click="goContact"
            >
              Kassalle
            </button>
            <button
              class="button mt-3 fiskars-button go-back-button"
              @click="goBack"
            >
              Palaa takaisin
            </button>
          </div>
        </div>
      </div>
    </div>
    <FiskarsFooter />
  </section>
</template>

<script>
import FiskarsNavbar from "@/components/FiskarsNavbar.vue";
import FiskarsFooter from "@/components/FiskarsFooter.vue";
import ProductsTable from "@/components/ProductsTable";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, computed, onMounted} from "vue";

export default {
  name: "CartPage",
  components: {
    FiskarsNavbar,
    FiskarsFooter,
    ProductsTable
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    let previewItemGlb = ref(null);
    let previewItemTextures = ref(null);
    let minAmountMsgError = ref(false);
    let voucherCode = ref("");
    let voucherCodeError = ref("");
    let voucherDiscount = ref("0");
    const sendingType = ref(store.getters.getSendingType);

    const setLoader = (state) => store.commit("setSpinnerState", state);


    const deleteVoucher = () => {
      voucherDiscount.value = "0";
      store.commit("setVoucherValue", "0");
      store.dispatch("partialUpdateOrder", {
        discount_code: null,
      });
    };

    const addNewProduct = () => {
      router.push({
        name: "Landing",
      });
    };

    const editItem = (item) => {
      router.push({
        name: "EditorPage",
        params: {
          orderUuid: store.getters.getOrderData.uuid,
          itemId: item.id,
          productId: item.product,
        },
      });
    };

    const goBack = () => {
      editItem(
        store.getters.getSavedItems[store.getters.getSavedItems.length - 1]
      );
    };

    const goContact = () => {
      if (store.getters.getItemsTotalAmount < 50) {
        minAmountMsgError.value = true
        return
      }
      minAmountMsgError.value = false

      router.push({
        name: "Contact",
        params: {
          orderUuid: store.getters.getOrderData.uuid,
        },
      });
    };

    const addVoucher = () => {
      voucherCodeError.value = "";
      if (voucherCode.value.length < 1) {
        voucherCodeError.value = "Lisää alennus koodi";
        return;
      }
      store
        .dispatch("addVoucher", {
          code: voucherCode.value,
        })
        .then((resp) => {
          if (resp.status === 404) {
            voucherCodeError.value = "Lisää oikea alennus koodi";
          } else {
            store.commit("setVoucherValue", resp.data)
            voucherDiscount.value = store.getters.getVoucherValue
            store.dispatch("partialUpdateOrder", {
              discount_code: resp.data.id,
            });
          }
        })
        .catch((err) => (voucherCodeError.value = err));
    };

    onMounted(() => {
      setLoader(false);
      store.dispatch("getOrder");
    });

    return {
      voucherCode,
      voucherCodeError,
      voucherDiscount,
      previewItemGlb,
      previewItemTextures,
      order: computed(() => store.getters.getOrderData),
      deleteVoucher,
      addNewProduct,
      goBack,
      goContact,
      store,
      addVoucher,
      minAmountMsgError,
      sendingType
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.cart-page {
  .add-product-button {
    min-width: 260px;
  }
  .go-back-button {
    min-width: 166px;
    background-color: #ffffff;
    color: #0d0d0c;
    border: 2px solid #0d0d0c;
    &:hover {
      background-color: #e9e9e9;
      border-color: #e9e9e9;
    }
  }

  .go-contact-button {
    min-width: 260px;
  }

}
</style>
