<template>
  <div class="columns is-multiline">
    <div class="column is-6 pb-0">
      <h2 class="mb-3">Suunnittele ja lähetä lahjaksi</h2>
      <p class="mb-3">
        Valitse pakkauksen väri neljästä vaihtoehdosta. Kirjoita haluamasi
        tekstit annettuihin kenttiin. Huomioithan että sallittuja kieliämme ovat
        suomi, ruotsi ja englanti. Lopuksi tallenna ja viimeistele tilaus
        maksamalla kassalla.
      </p>
      <p>
        Lahja toimitetaan antamaasi osoitteeseen 10–14 arkipäivän kuluessa.
        Lahjapakkauksen materiaali on suomalaista kierrätettävää kartonkia.
      </p>
      <div class="divider"></div>
    </div>
  </div>

  <div class="columns is-multiline">
    <div class="column is-6">
      <div class="tabs is-boxed">
        <ul>
          <li
            :class="{
              'is-active': sendingType == 'FREIGHT',
              'is-disabled': finalSendingType == 'MAILBOX',
            }"
            @click="changeSendingType('FREIGHT')"
          >
            <a class="tab-text"> Yksi toimitusosoite </a>
          </li>
          <li class="tab-separator"></li>
          <li
            :class="{
              'is-active': sendingType == 'MAILBOX',
              'is-disabled': finalSendingType == 'FREIGHT',
            }"
            @click="changeSendingType('MAILBOX')"
          >
            <a class="tab-text"> Useampi toimitusosoite </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <template v-if="sendingType == 'FREIGHT'">
    <div class="columns">
      <div class="column is-6 pt-0">
        <div class="columns is-multiline">
          <div class="column is-12">
            <p>Suunnittelet parhaillaan:</p>
            <h4>Classic yleissakset 21 cm, lahjapakkauksella</h4>
          </div>
          <div class="column is-10">
            <div class="image-container is-relative">
              <img
                class="is-block"
                :src="require(`../assets/img/${activeBgImg}`)"
              />
              <div v-if="isFrontSelected" class="front-text-wrapper">
                <p v-html="previewFrontText" class="front-preview-text"></p>
              </div>
              <div v-if="!isFrontSelected" class="back-text-wrapper">
                <p v-html="previewBackText" class="back-preview-text"></p>
              </div>
            </div>
          </div>
          <div class="column is-2">
            <ul>
              <li class="mb-5" @click="isFrontSelected = true">
                <div
                  class="outer-side-square is-clickable"
                  :class="{ active: isFrontSelected }"
                  :style="{ borderColor: `${colourIdxHex[colourIdxSelected - 1]}` }"
                >
                  <div
                    class="inner-side-square"
                    :class="{ active: isFrontSelected }"
                    :style="{ backgroundColor: colourIdxHex[colourIdxSelected - 1] }"
                  ></div>
                </div>
                <span :class="{ 'active-span': isFrontSelected }">Etu</span>
              </li>
              <li @click="isFrontSelected = false">
                <div
                  class="outer-side-square is-clickable"
                  :class="{ active: !isFrontSelected }"
                  :style="{ borderColor: `${colourIdxHex[colourIdxSelected - 1]}` }"
                >
                  <div
                    class="inner-side-square"
                    :class="{ active: !isFrontSelected }"
                    :style="{ backgroundColor: colourIdxHex[colourIdxSelected - 1] }"
                  ></div>
                </div>
                <span :class="{ 'active-span': !isFrontSelected }">Taka</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6 pb-0">
        <h3>Väri</h3>
        <ul class="horizontal-list">
          <li v-for="(bgSrc, idx) in backgroundsSrc" :key="idx">
            <button
              class="p-2 is-clickable"
              :class="{ 'wrapper-selected': idx + 1 == colourIdxSelected }"
              @click="colourIdxSelected = idx + 1"
            >
              <figure class="image is-64x64">
                <img
                  class="image is-64x64"
                  :src="require(`../assets/img/${bgSrc}`)"
                  alt=""
                />
              </figure>
            </button>
          </li>
        </ul>
        <div class="divider"></div>
      </div>
    </div>

    <div class="columns">
      <div class="column pt-0">
        <h4 v-if="isFrontSelected">Etuosan teksti</h4>
        <h4 v-else>Takaosan teksti</h4>
        <p>Kirjoita oma viestisi tai valitse valmis teksti</p>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <TextEditor
          v-show="isFrontSelected"
          :notext="false"
          :maxRows="4"
          @inputtextarea="inputFrontTextarea"
          :maxCharactersPerRow="22"
          :maxCharsLength="44"
          :producttext="frontText"
          name="text-editor-front-text-desktop"
          ref="frontTextEditor"
        >
        </TextEditor>
        <TextEditor
          v-show="!isFrontSelected"
          :notext="false"
          :maxRows="6"
          @inputtextarea="inputBackTextarea"
          :maxCharactersPerRow="40"
          :maxCharsLength="140"
          :producttext="backText"
          :fontStyle="{ 'min-height': '125px' }"
          name="text-editor-back-text-desktop"
          ref="backTextEditor"
        >
        </TextEditor>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <div class="control" v-show="isFrontSelected">
          <label
            v-for="message in defaultFrontMessages"
            :key="message.id"
            class="radio fiskars-radio is-block ml-0 mb-3"
          >
            <input
              type="radio"
              name="message"
              v-model="messageFrontId"
              :value="message.id"
            />
            {{ message.text }}
          </label>
        </div>
        <div class="control" v-show="!isFrontSelected">
          <label
            v-for="message in defaultBackMessages"
            :key="message.id"
            class="radio fiskars-radio is-block ml-0 mb-3"
          >
            <input
              type="radio"
              name="message"
              v-model="messageBackId"
              :value="message.id"
            />
            {{ message.text }}
          </label>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6 has-text-right">
        <button class="button fiskars-button" @click="saveAndCart">
          Tallenna ja lisää koriin
        </button>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6 has-text-right">
        <button class="button fiskars-button" @click="saveAndLanding">
          Tallenna ja lisää uusi tuote
        </button>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="columns">
      <div class="column is-6 pt-0">
        <p>Suunnittelet parhaillaan:</p>
        <h4 class="mb-5">Classic yleissakset 21 cm, lahjapakkauksella</h4>
        <p class="mb-4">
          Asiakaspalvelumme hoitaa tilauksen jatkokäsittelyn excel-tiedoston
          avulla. Lähetämme sinulle tiedoston, jossa on ohjeet tekstien ja
          osoitteiden kirjaamiselle. Tilauksesi käsitellään valmiiksi, kun
          olemme saaneet sinulta taulukon takaisin.
        </p>
        <p>Valitse alta, montako tuotetta haluat tilata.</p>
      </div>
    </div>

    <div class="columns">
      <div
        class="column is-6 is-flex is-flex-direction-row is-align-items-center"
      >
        <div
          class="freight-product-color"
          :style="{ backgroundColor: colourIdxHex[colourIdxSelected - 1] }"
        ></div>
        <p class="freight-product-name">Classic yleissakset 21 cm</p>
        <div class="amount-wrapper">
          <ProductAmount
            :isEditable="true"
            v-model="freightItem.amount"
            :itemId="freightItem.id"
            @amountupdated="updateFreightAmount"
          />
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6 pb-0">
        <div class="divider"></div>
        <h3>Väri</h3>
        <ul class="horizontal-list">
          <li v-for="(bgSrc, idx) in backgroundsSrc" :key="idx">
            <button
              class="p-2 is-clickable"
              :class="{ 'wrapper-selected': idx + 1 == colourIdxSelected }"
              @click="colourIdxSelected = idx + 1"
            >
              <figure class="image is-64x64">
                <img
                  class="image is-64x64"
                  :src="require(`../assets/img/${bgSrc}`)"
                  alt=""
                />
              </figure>
            </button>
          </li>
        </ul>
        <div class="divider"></div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6">
        <p>Seuraavaksi jatka ostoskoriin.</p>
      </div>
    </div>

    <div class="columns">
      <div class="column is-6 has-text-right">
        <button class="button fiskars-button" @click="saveAndCart">
          Jatka ostoskoriin
        </button>
      </div>
    </div>
  </template>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import TextEditor from "@/components/TextEditor.vue";
import ProductAmount from "@/components/ProductAmount.vue";

export default {
  name: "FiskarsEditor",
  props: {
    modelValue: Number,
    isEditable: Boolean,
    product: Number,
    id: String,
    itemid: Number,
    itemAmount: Number,
  },
  components: {
    TextEditor,
    ProductAmount,
  },
  setup(props) {
    // Variables
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const frontText = ref("");
    const backText = ref("");
    const messageFrontId = ref(null);
    const messageBackId = ref(null);
    const backgroundsSrc = ref([
      "Ellipse1.png",
      "Ellipse2.png",
      "Ellipse3.png",
      "Ellipse4.png",
    ]);
    const frontBackSrc = ref([
      ["front_1_beetroot.png", "back_1_beetroot.png"],
      ["front_2_green.png", "back_2_green.png"],
      ["front_3_terracotta.png", "back_3_terracotta.png"],
      ["front_4_black25.png", "back_4_black25.png"],
    ]);
    const defaultFrontMessages = ref([
      { id: 1, text: "Olet ihanan terävä!" },
      { id: 2, text: "Kiitos yhteistyöstä." },
      { id: 3, text: "Hyvää joulua!" },
    ]);
    const defaultBackMessages = ref([
      {
        id: 1,
        text: "Lahja sinulle, jotta jatkossakin leikkaa hyvin! Terveisin, Yritys Oyj",
      },
      { id: 2, text: "Kiitos hyvästä yhteistyöstä. Terveisin, Yritys Oyj" },
      { id: 3, text: "Tunnelmallista joulua ja menestyksekästä uutta vuotta 2025 toivottaen Yritys Oyj" },
    ]);
    const colourIdxSelected = ref(1); // 1-4   red // green // brown // grey
    const colourIdxHex = ref(["#60073A", "#18301A", "#301F14", "#BFB8AC"]);
    const isFrontSelected = ref(true);
    const sendingType = ref(store.getters.getSendingType);
    const finalSendingType = computed(() => {
      // returns order sending type when at least an item as been created
      // used to hide opposite sending options in frontend
      let order = store.getters.getOrderData;
      let item = order.items.find(itm => !itm.draft); // select if there is any draft == false
      if (item) {
        return order.delivery_type;
      }
      return "";
    });
    const freightItem = computed(() => {
      let item =
        store.getters.getOrderData.items[
          store.getters.getOrderData.items.length - 1
        ];
      if (item.amount < 50) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        store.getters.getOrderData.items[
          store.getters.getOrderData.items.length - 1
        ].amount = 50;
      }
      return store.getters.getOrderData.items[
        store.getters.getOrderData.items.length - 1
      ];
    });
    const activeBgImg = computed(() => {
      let sideIdx = isFrontSelected.value ? 0 : 1;
      return frontBackSrc.value[colourIdxSelected.value - 1][sideIdx];
    });
    const previewFrontText = computed(() => {
      return frontText.value.replace(/\n/g, "<br>");
    });
    const previewBackText = computed(() => {
      return backText.value.replace(/\n/g, "<br>");
    });
    const inputFrontTextarea = (event) => {
      frontText.value = event;
    };
    const inputBackTextarea = (event) => {
      backText.value = event;
    };

    watch(messageFrontId, (currentValue, oldValue) => {
      if (currentValue != oldValue && currentValue != null) {
        frontText.value = defaultFrontMessages.value.find(
          (el) => el.id == currentValue
        ).text;
      }
    });
    watch(messageBackId, (currentValue, oldValue) => {
      if (currentValue != oldValue && currentValue != null) {
        backText.value = defaultBackMessages.value.find(
          (el) => el.id == currentValue
        ).text;
      }
    });

    const saveAndCart = async () => {
      const { itemId, orderUuid } = route.params;
      const order = store.getters.getOrderData;

      let initAmount = 50;

      if (sendingType.value === "MAILBOX") {
        frontText.value = "";
        backText.value = "";
        initAmount = freightItem.value.amount;
      }

      await store.dispatch("partialUpdateOrder", {
        discount_code: null,
        delivery_type: sendingType.value,
      });

      await store.dispatch("updateItem", {
        id: itemId,
        order: order.order_number,
        design: colourIdxSelected.value, // 1, 2, 3, 4
        front_text: frontText.value,
        back_text: backText.value,
        draft: false,
        amount: initAmount,
      });

      await store.dispatch("getOrder");
      router.push({
        name: "Cart",
        params: { orderUuid },
      });
    };

    const saveAndLanding = async () => {
      const order = store.getters.getOrderData;
      let initAmount = 50;
      await store.dispatch("partialUpdateOrder", {
        discount_code: null,
        delivery_type: sendingType.value,
      });
      await store.dispatch("updateItem", {
        id: route.params.itemId,
        order: order.order_number,
        design: colourIdxSelected.value, // 1, 2, 3, 4
        frontText: frontText.value,
        backText: backText.value,
        draft: false,
        amount: initAmount,
      });

      await store.dispatch("getOrder").then(() => {
        router.push({
          name: "Landing",
        });
      });
    };

    const changeSendingType = (value) => {
      if (sendingType.value == value || finalSendingType.value) return;
      sendingType.value = value;
      store.commit("setSendingType", value);
    };

    const updateFreightAmount = (itemid, amount) => {
      freightItem.value.amount = amount;
    };

    onMounted(() => {
      store.dispatch("getOrder").then(() => {
        let item = store.getters.getOrderData.items.find(el => el.id == route.params.itemId)
        if (item) {
          colourIdxSelected.value = item.design ? item.design : 1
          frontText.value = item.front_text ? item.front_text : ''
          backText.value = item.back_text ? item.back_text : ''
        }
      })
    });

    return {
      frontText,
      backText,
      backgroundsSrc,
      frontBackSrc,
      isFrontSelected,
      colourIdxSelected,
      activeBgImg,
      previewFrontText,
      previewBackText,
      defaultFrontMessages,
      defaultBackMessages,
      messageFrontId,
      messageBackId,
      inputFrontTextarea,
      inputBackTextarea,
      saveAndCart,
      saveAndLanding,
      sendingType,
      changeSendingType,
      freightItem,
      colourIdxHex,
      updateFreightAmount,
      finalSendingType,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
button {
  padding: 0;
  display: inline-block;
  background: none;
  border: none;
}
.wrapper-selected {
  border: 1px solid #000000;
}
.horizontal-list {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.horizontal-list li {
  margin-right: 15px;
}
.outer-side-square {
  width: 73px;
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  border-width: 2px;
  border-style: solid;
  &.active {
    border-width: 3px;
    opacity: 1;
  }
}
.inner-side-square {
  width: 52px;
  height: 52px;
  opacity: 0.7;
  &.active {
    opacity: 1;
  }
}
.front-text-wrapper {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .front-preview-text {
    color: #fff;
    font-family: helvetica-now-text-medium;
    font-size: 20px;
    text-align: left;
  }
}

#text-editor-back-text-desktop {
  min-height: 125px;
}

.back-text-wrapper {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 8%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .back-preview-text {
    text-align: left;
    color: #fff;
    font-family: helvetica-now-text-medium;
    font-size: 20px
  }
}

.tabs ul li {
  a {
    text-decoration: none;
    color: $fiskars-black;
    background-color: #f2eadf;
    border: 2px solid $fiskars-black !important;
  }
}

.tabs ul li.is-active {
  a {
    font-family: helvetica-now-display-xbd;
    color: $fiskars-black;
    border-top: 2px solid $fiskars-black !important;
    border-left: 2px solid $fiskars-black !important;
    border-right: 2px solid $fiskars-black !important;
    border-bottom: 0px !important;
  }
}

.tabs ul li.is-disabled {
  opacity: 0.3;
  a {
    cursor: not-allowed;
  }
}

.tab-separator {
  border-bottom: 2px solid $fiskars-black !important;
  background-color: #fff;
  height: 100%;
  width: 13px;
}
.freight-product-color {
  height: 85px;
  width: 122px;
  background-color: red;
}
.freight-product-name {
  margin-left: 10px;
  margin-right: 100px;
}
.amount-wrapper {
  display: flex;
  justify-content: center;
}
.active-span {
  font-family: helvetica-now-display-xbd;
}
</style>
