import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store'
import {waitForStorageToBeReady} from '../utils'
import { trackRouter } from "vue-gtag-next"

import LandingPage from '../views/LandingPage'
import ProductDetailPage from '../views/ProductDetailPage'
import CartPage from '../views/CartPage'
import ContactPage from '../views/ContactPage'
import ThanksPage from '../views/ThanksPage'
import EditorPage from "../views/EditorPage";
import CustomerService from "../views/CustomerServicePage";
import CookiePolicyPage from "../views/CookiePolicyPage";

var hash = location.hash

if (hash && hash.indexOf('#!') === 0) {
  location.hash = hash.slice(2)
}

export const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        name: 'Landing',
        component: LandingPage,
      },
      {
        path: '/customer-service',
        name: 'CustomerService',
        component: CustomerService,
      },      
      {
        path: '/:productId/product-detail',
        name: 'ProductDetail',
        component: ProductDetailPage,
      },
      {
        path: '/:orderUuid/cart',
        name: 'Cart',
        component: CartPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/:orderUuid/contact',
        name: 'Contact',
        component: ContactPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/thanks',
        name: 'ThanksPage',
        component: ThanksPage,
        meta: { requiresAuth: false }
      },
      {
        path: '/:orderUuid/:itemId/:productId/editor',
        name: 'EditorPage',
        component: EditorPage,
        meta: { requiresAuth: true }
      },
      {
        path: '/cookie-policy',
        name: 'CookiePolicy',
        component: CookiePolicyPage,
      }      

    ],
    scrollBehavior (to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth'
        };
      } else {
        document.getElementById('app').scrollIntoView();
      }
    }
  });

  router.beforeEach(async (to, from, next) => {
    await waitForStorageToBeReady()
    // If user has accepted cookies, we do the manual dataLayer push they requested (see december/genuary emails)
    if (store.getters.getGoogleCookieEnabled) {
      dataLayer.push({event:'cookie_consented'})
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
      // If we are passing token in request
      if (to.query.token) {
        store.commit('setAuth', to.query.token)
        if (to.name === 'EditorPage') {
          await store.dispatch('getProducts')
          await store.dispatch('getOrder', to.query.order_id)
        }
      }
      if (!store.getters.getToken) {
        next({
          path: '/'
        })
      } else {
        next()
      }
    } else {
      next()
    }
  });

  trackRouter(router);

  export default router
