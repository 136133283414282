import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default function addProduct() {
  const store = useStore();
  const router = useRouter();

  function selectProduct(id) {
    store.commit("setSelectedProduct", id);
    store.dispatch("getAuthenticate").then(() => {
      const order = store.getters.getOrderData;

      if (order && order.uuid != undefined) {
        store
          .dispatch("postItem", {
            order: order.order_number,
            product: id,
            draft: true
          })
          .then((res) => {
            store.dispatch("getOrder").then(() => {
              router.push({
                name: "EditorPage",
                params: {
                  orderUuid: store.getters.getOrderData.uuid,
                  itemId: res.data.id,
                  productId: id,
                },
              });
            });
          });
      } else {
        store.dispatch("postOrder").then(() => {
          store
            .dispatch("postItem", {
              order: store.getters.getOrderData.order_number,
              product: id,
              draft: true
            })
            .then((res) => {
              store.dispatch("getOrder").then(() => {
                router.push({
                  name: "EditorPage",
                  params: {
                    orderUuid: store.getters.getOrderData.uuid,
                    itemId: res.data.id,
                    productId: id,
                  },
                });
              });
            });
        });
      }
    });
  }

  return {
    selectProduct,
  };
}
