<template>
  <div class="menu hero-head">
    <nav class="navbar has-background-black" role="navigation" aria-label="main navigation">

        <div class="navbar-brand is-hidden-touch">
          <a class="navbar-item" href="/">
          <img class="fiskars-logo" src="../assets/img/Fiskars_logo_Orange_RGB@2x.png" width="160">
          </a>
        </div>

        <div class="navbar-menu is-hidden-touch">
          <div class="navbar-start">
            <p class="navbar-item custom-shop-subheader has-text-white">
              Anna lahjaksi ikoniset Fiskars sakset yrityksesi omalla viestillä
            </p>
          </div>

          <div class="navbar-end is-hidden-touch">
            <div class="navbar-item">
              <div class="is-clickable fiskars-icon-wrapper" @click="clickCartModal()">
                <OnClickOutside @trigger="openCartModal=false">
                  <div v-if=openCartModal class="triangle"></div>
                  <img class="fiskars-icon" src="../assets/icons/icon_cart_white.svg">
                  <span class="items-count" v-if="totalItemsCount">{{ totalItemsCount }}</span>
                  <cart-modal v-if=openCartModal></cart-modal>
                </OnClickOutside>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-hidden-desktop is-mobile ml-0 mr-0">
          
          <div class="column is-4">
            
              <div class="navbar-brand">
                <a class="navbar-item" href="/">
                  <img class="fiskars-logo-mobile" src="../assets/img/Fiskars_logo_Orange_RGB@2x.png">
                </a>
              </div>
            
          </div>

          <div class="column is-8">
            <div class="is-flex is-justify-content-end">
              <div class="navbar-item">
                <div class="is-clickable fiskars-icon-wrapper" @click="clickCartModalMobile()">
                  <OnClickOutside @trigger="openCartModalMobile=false">
                    <img class="fiskars-icon" src="../assets/icons/icon_cart_white.svg">
                    <span class="items-count" v-if="totalItemsCount">{{ totalItemsCount }}</span>
                    <cart-modal v-if=openCartModalMobile></cart-modal>
                  </OnClickOutside>
                </div>
              </div>
            </div>
          </div>
        </div>
    </nav>
    
    <SubNavbar class="is-hidden-touch" />
  </div>
</template>

<script>
import SubNavbar from '@/components/SubNavbar.vue'
import { OnClickOutside } from '@vueuse/components'
import cartModal from "@/components/modals/cartModal"
import { computed, ref } from "vue"
import { useStore } from "vuex";

export default {
  name: 'FiskarsNavbar',
  components: {
    SubNavbar,
    OnClickOutside,
    cartModal
  },
  setup() {
    const store = useStore();
    const openCartModal = ref(false)
    const openCartModalMobile = ref(false)

    const clickCartModal = () => {
      openCartModal.value = !openCartModal.value
    }
    const clickCartModalMobile = () => {
      openCartModalMobile.value = !openCartModalMobile.value
    }


    return {
      totalItemsCount: computed(() => store.getters.getItemsTotalAmount),
      openCartModal,
      openCartModalMobile,
      clickCartModal,
      clickCartModalMobile,
    }
  },
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.menu .navbar {
  height: 70px;
  position: fixed;
  background-color: white;
  width: 100%;
  .custom-shop-subheader {
    font-size: 20px;
    font-family: helvetica-now-display-xbd;
    margin-top: 6px;
  }
}

.menu {
  @media screen and (max-width: 1024px) {
    padding-bottom: 51px !important;
  }
}

.fiskars-icon-wrapper {
  position: relative;
  @media screen and (max-width: 768px) {
    width: 36px;
    height: 36px;
    line-height: 47px;
    .fiskars-icon {
      width: 22px;
      height: 22px;
    }
  }
}

.items-count {
  position: absolute;
  background: #ae0e3c;
  color: #ffffff;
  height: 18px;
  left: 13px;
  top: -7px;
  border-radius: 50%;
  line-height: normal;
  font-size: 13px;
  @media screen and (max-width: 768px) {
    font-size: 10px;
    height: 13px;
    left: 11px;
  }
}

.triangle {
  z-index: 100;
  position: absolute;
  right: 14px;
  bottom: -2px;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-right-width: 10px;
  border-left-width: 10px;
  border-bottom: 10px solid white;
}

.fiskars-logo {
  max-height: 100% !important;
  margin-left: -12px;
}

.fiskars-logo-mobile {
  max-height: 57px !important;
}

@media screen and (max-width: 768px) {
  .menu .navbar {
    height: 60px;
  }
}

.logo-title-mobile {
  margin-top: 4px;
}

.is-iittala-bold {
  font-family: helvetica-now-display-xbd;
}
</style>
