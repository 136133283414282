<template>
  <section class="hero thanks-page is-fullheight">
    <FiskarsNavbar />
    <div class="hero-body pt-0">
      <div class="container">
        <div class="columns mb-5 py-5">
          <div class="column mb-5 pb-5">
            <template v-if="!store.getters.getStripeError">
              <h2 class="mb-5 pb-4">Kiitos tilauksestasi!</h2>
              <p>
                Tilauksesi on vastaanotettu. Olemme iloisia, että valitsit ajatonta
                muotoilua yksilöllisesti paketoituna.
              </p>
            </template>
            <template v-else>
              <h2 class="error-color mb-5 pb-4">Tilauksesi veloitus epäonnistui</h2>
              <p class="error-color">
                Harmillista, näyttää että maksutapahtumasi epäonnistui. Yritäthän uudestaan tai ota yhteyttä palvelumme asiakaspalveluun customfiskars@packagemedia.fi.
              </p>              
            </template>

          </div>
        </div>

        <div class="columns is-multiline m-0 mt-5">
          <div
            class="column is-6 is-flex is-flex-direction-column is-justify-content-center"
          >
            <div class="columns">
              <div class="column is-8 is-offset-2 p-0">
                <h2>Fiskars lahjat yrityksille</h2>
                <p class="my-5 py-5">
                  Ostamalla Fiskarsin tuotteita tuet kotimaista työtä. Yrityksemme perustettiin vuonna 1649 Fiskarsissa. Brändeistämme Iittala, Fiskars, Arabia ja Hackman ovat vahvasti suomalaisia brändejä, joita suomalaiset rakastavat ja arvostavat. Maailmaa valloittaneet Fiskarsin oranssit sakset sekä kaikki Fiskarsin kirveet valmistuvat Billnäsissä Raaseporissa, kun taas Fiskarsin keitto- ja paistoastioita valmistetaan Sorsakoskella. Fiskars tuotteet ovat laadukkaita ja haluttuja lahjoja.
                </p>
                <a
                  class="button fiskars-button mb-5"
                  href= "mailto: yritysmyynti@fiskars.com"
                >
                  yritysmyynti@fiskars.com
              </a>                
              </div>
            </div>
          </div>
          <div class="column is-6 p-0">
            <img
              src="../assets/img/thanks_page_img_1.png"
              class="fiskar-img-1"
            />
          </div>
          <div class="column is-6 p-0">
            <img
              src="../assets/img/scissors_box1.jpg"
              class="fiskar-img-1"
            />
          </div>
          <div
            class="column is-6 is-flex is-flex-direction-column is-justify-content-center"
          >
            <div class="columns">
              <div class="column is-8 is-offset-2 p-0">
                <h2>Suunnittele uusi pakkaus</h2>
                <p class="my-5 py-5">
                  Aikaa ja käyttöä kestävä lahja on täydellinen tapa kiittää henkilöstöä ja yhteistyökumppaneita hyvästä työpanoksesta, tehostaa sisäistä markkinointia sekä edistää myyntiä.
                </p>
                <button
                  class="button fiskars-button mb-5"
                  @click="goLanding"
                >
                  Suunnittele lahjapakkaus
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FiskarsFooter />
  </section>
</template>

<script>
import FiskarsNavbar from "@/components/FiskarsNavbar.vue";
import FiskarsFooter from "@/components/FiskarsFooter.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { onMounted } from "vue";
import { useState } from "vue-gtag-next";

export default {
  name: "ThanksPage",
  components: { 
    FiskarsNavbar,
    FiskarsFooter,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const { property } = useState();

    const goLanding = () => {
      router.push({
        name: "Landing",
      });
    };
    onMounted(() => {
      // Since we come from stripe, GA ID has been lost so we add it again if needed
      if(store.getters.getGoogleCookieEnabled) {
        property.value = {
          id: process.env.VUE_APP_GA_ID
        }
      }
      store.commit("setReseting", true);
      store.commit("resetStore");

    });
    return {
      goLanding,
      store,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.thanks-page {
  .fiskar-img-1 {
    width: 100%;
    height: 100%;
  }
  .error-color {
    color: #AF0F3C;
  }
  button, a {
    min-width: 202px;
  }
}
</style>
