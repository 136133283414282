<template>
  <div class="popover-content">
    <div v-for="(item, index) in items" :key="index" class="columns is-size-5 is-vcentered is-mobile mb-0 mt-3 ml-3 mr-3">
      <div class="column is-4 pb-0 pt-2">
        <div class="is-flex is-justify-content-start is-align-items-start img-wrapper">
          <img :src="getItemImageUrl(item)" />
        </div>
      </div>
      <div class="column is-4-desktop is-5-mobile pb-0 pt-2">
        <div class="text-wrapper ml-3 is-justify-content-center is-align-items-start">
          <p>{{ getProductName(item) }}</p>
          <p class="mt-3">Määrä {{ item.amount }}</p>
        </div>
      </div>
      <div class="column is-4-desktop is-3-mobile pb-0 pt-2" style="line-height: normal;">
        <div class="text-wrapper ml-3 is-flex is-justify-content-end is-align-items-start">
         <p>{{ getTotalPricePerProduct(item) }}€</p>
        </div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="columns is-size-5 is-vcentered is-mobile mb-0 is-multiline mt-3 ml-3 mr-3">
      <div class="column is-4 pb-0">
        <div class="text-wrapper-pricing is-total-pricing is-flex is-justify-content-start is-align-items-start ml-3">
          Yhteensä
        </div>
      </div>
      <div class="column is-6 is-offset-2 pb-0">
        <div class="text-wrapper-pricing is-total-pricing is-flex is-justify-content-end is-align-items-start ml-3 mb-0 pb-0">
         {{ totalPriceVat }} €
        </div>
        <div class="pricing-help is-flex is-justify-content-end is-align-items-start ml-3 mt-0 pt-0">
         Sisältäen verot
        </div>
      </div>
    </div>
    <div class="columns is-mobile mt-5">
      <div class="column is-6 has-text-left">
        <button
          class="fiskars-white-button button"
          @click="resetCart()"
        >
          <span>Ostoskorisi</span>
      </button>
      </div>
      <div class="column is-6 has-text-right">
        <button
          class="fiskars-button button"
          @click="goToCart()"
        >
          <span>Kassalle</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import modalMixing from "@/components/mixins/modalMixing";
import cartMixin from "@/components/mixins/cartMixin.js";
import {computed} from "vue";
import {useStore} from "vuex";
import { useRouter } from 'vue-router';

export default {
  name: 'cartModal',
  mixins: [
    modalMixing,
    //cartMixin //TODO
  ],
  setup() {
    const store = useStore();
    const router = useRouter();
    const publicPath = process.env.BASE_URL;
    const {
      getItemImageUrl,
      getPricePerProduct,
      getTotalPricePerProduct,
      getProductName,
      openPreview,
    } = cartMixin();

    const resetCart = () => {
      if ('uuid' in store.getters.getOrderData) {
        router.push({
          name: "Cart",
          params: {
            orderUuid: store.getters.getOrderData.uuid,
          },
        })
      }
    }
    const goToCart = () => {
      if ('uuid' in store.getters.getOrderData) {
        router.push({
          name: "Contact",
          params: {
            orderUuid: store.getters.getOrderData.uuid,
          },
        })
      }
    }

    return {
      order: computed(() => store.getters.getOrderData),
      items: computed(() => store.getters.getSavedItems),
      totalPriceVat: computed( () => store.getters.getTotalOrderPriceVat),
      getItemImageUrl,
      getPricePerProduct,
      getTotalPricePerProduct,
      getProductName,
      openPreview,
      publicPath,
      resetCart,
      goToCart,
      store
    }
  },
}
</script>

<style lang="scss" scoped>
.is-layout-fixed{
  position: fixed !important;
}

.mobile-modal {
  .buttons {
    right: 0;
    top: 0;
    position: absolute;
  }
}

.popover-content {
  box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
  z-index: 10;
  position: absolute;
  background-color: white;
  width: 525px;
  right: 12px;
  padding: 20px 20px 20px 20px;
  top: 47px;
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;
  @media (max-width: 768px) {
    width: 100vw;
    right: -24px;
  }
  p {
    font-size: 20px;
  }
  img {
    object-fit: cover;
    width: 65px;
    height: 65px;
    max-height: none;
  }
}

.text-wrapper {
  height: 104px;
  color: #0D0D0C;
  p {
    font-size: 12px;
    line-height: normal;
    text-align: left;
  }
}

.text-wrapper-pricing {
  font-size: 16px;
  height: 30px;
  font-family: helvetica-now-display-xbd;
  font-weight: bold;
  line-height: normal;
}

.pricing-help {
  font-size: 13px;
  line-height: normal;
  color: black;
}

.is-total-pricing {
  font-size: 20px;
  color: black;
}

.img-wrapper {
  height: 104px;
}

.button {
  border: 1px solid black;
  height: 66px;
  width: 166px;
  border-radius: 0;
}

.border-bottom-gray {
  border-bottom: 2px solid #d0d3d4;
}

.cart-logo-small {
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  &.portrait {
    right: 5px;
    bottom: 5px;
    width: 15px;
    height: 15px;
  }
  &.landscape {
    right: 8px;
    bottom: 8px;
    @media (max-width: 768px) {
      right: 5px;
      bottom: 5px;
    }
  }
}
</style>
