<template>
  <section class="hero product-detail-wrapper is-fullheight">
    <FiskarsNavbar />
    <div class="hero-body pt-0">
      <div class="container pt-5">
        <div class="columns is-multiline">
          <div class="column is-8">
            <div class="columns is-multiline is-mobile">
              <div class="column is-6 is-6-touch">
                <figure class="image is-1by1">
                  <img src="../assets/img/product_766.png" />
                </figure>
              </div>
              <div class="column is-6 is-6-touch is-flex product-3d-thumbnail">
                <img src="../assets/img/scissors_box4.jpg" />
              </div>
              <div class="column is-6 is-6-touch">
                <figure class="image is-1by1">
                  <img
                    src="../assets/img/1007578_IittalaEMEA_02_iittala-kastehelmi-votive-64-mm-clear.png"
                  />
                </figure>
              </div>
              <div class="column is-6 is-6-touch">
                <figure class="image is-1by1">
                  <img
                    src="../assets/img/Fiskars_375_symbol_primary@2x.png"
                  />
                </figure>
              </div>              
            </div>
          </div>

          <div class="column is-4">
            <p class="product-family font-13">{{ product.family }}</p>
            <h3 class="product-name mt-4">{{ product.name }}</h3>
            <h3 class="product-price mb-4 mt-2">
              {{ (product.price / 100).toFixed(2).replace(".", ",") }}€
            </h3>
            <p class="font-16 mt-6">Sisältää lahjapakkauksen. Minimitilausmäärä on 50 kappaletta. Toimitus joko suoraan vastaanottajien postilaatikkoon tai isommassa erässä rahtina haluttuun osoitteeseen Suomessa. Lahjapakkauksen toimitusaika on 10-14 arkipäivää.</p>

            <button
              class="button fiskars-button product-detail-button mt-5 mb-3"
              @click="selectProduct(product.id)"
            >
              Suunnittele lahjapakkaus
            </button>

            <div class="divider"></div>

            <h4 class="mb-4">
              Suunnittele lahjapakkaus ja lähetä lahjaksi
            </h4>
            <p class="font-16">
              Anna lahjaksi ajatonta muotoilua yksilöllisesti paketoituna.
              Suunnittele Iittala-lahjapakkaus henkilökohtaisella viestillä ja
              teet lahjastasi vieläkin merkityksellisemmän. Voit valita
              haluamasi kuosin neljästä vaihtoehdosta ja lisätä pakkaukseen oman
              viestisi. Lahjapakkauksen materiaali on suomalaista kierrätettävää
              kartonkia. Lahjapakkauksen toimitusaika on 10–14 arkipäivää.
            </p>
            <a class="font-16 mt-5" @click="selectProduct(product.id)"
              >Suunnittele lahjapakkaus</a
            >

            <div class="divider"></div>

            <p class="font-16">
              Ikoni jo syntyessään. Fiskarsin yleissakset tunnetaan uniikeista
              oransseista kahvoistaan, ensiluokkaisesta leikkuutehostaan ja
              kestävyydestään. Terävien ja huippulaadukkaiden, ruostumattomasta
              teräksestä valmistettujen terien ja käteen hyvin istuvien
              muovisten kahvojen ansiosta sakset sopivat erinomaisesti
              kaikenlaisiin kodin leikkuutöihin – kerta toisensa jälkeen.
              <br /><br />
              Fiskarsin 375-juhlavuoden erikoissakset ovat kunnianosoitus
              luovuudelle ja esimerkki siitä, miten oikeanlaisilla työkaluilla
              voi tuoda lisää iloa arjen askareisiin. Jo usean sukupolven
              rakastamat, Suomessa suunnitellut, valmistetut ja käsin testatut
              sakset soveltuvat luoviin projekteihin vuosikymmeniksi eteenpäin.
            </p>

            <div class="divider"></div>

            <div id="accordion_second">
              <article class="message">
                <div class="message-header p-0">
                  <a
                    href="#collapsible-message-accordion-second-1"
                    data-action="collapse"
                    class="no-underline font-16 collapse-header-text"
                    @click="clickCollapsibleItem(0)"
                    >Tuotteen ominaisuudet</a
                  >
                  <a
                    href="#collapsible-message-accordion-second-1"
                    data-action="collapse"
                    @click="clickCollapsibleItem(0)"
                  >
                    <img
                      v-if="collapsedFirstItem"
                      src="../assets/icons/icon_minus_circle.svg"
                    />
                    <img v-else src="../assets/icons/icon_plus_circle.svg" />
                  </a>
                </div>
                <div
                  id="collapsible-message-accordion-second-1"
                  class="message-body is-collapsible"
                  data-parent="accordion_second"
                  data-allow-multiple="true"
                >
                  <div class="message-body-content p-0 font-16">
                    - Arjen leikkuutöihin ja luoviin projekteihin<br />
                    - Terässä 375-juhlavuoden erityisleima<br />
                    - Ergonominen kahva takaa paremman käyttömukavuuden ja
                    hallinnan tunteen<br />
                    - Terävät, korkealaatuiset ruostumattomasta teräksestä
                    valmistetut terät<br />
                    - Pitkäkestoista leikkuutehoa<br />
                    - Soveltuvat monenlaisten paksujen ja ohuiden materiaalien
                    leikkaamiseen kotona ja pihapiirissä<br />
                    - Oikeakätiset<br />
                    - Lahjapakkaus<br />
                    - 25 vuoden takuu<br /><br />
                  </div>
                </div>
              </article>

              <div class="divider"></div>

              <article class="message">
                <div class="message-header p-0">
                  <a
                    href="#collapsible-message-accordion-second-2"
                    data-action="collapse"
                    class="no-underline font-16 collapse-header-text"
                    @click="clickCollapsibleItem(1)"
                    >Tuotetiedot</a
                  >
                  <a
                    href="#collapsible-message-accordion-second-2"
                    data-action="collapse"
                    @click="clickCollapsibleItem(1)"
                  >
                    <img
                      v-if="collapsedSecondItem"
                      src="../assets/icons/icon_minus_circle.svg"
                    />
                    <img v-else src="../assets/icons/icon_plus_circle.svg" />
                  </a>
                </div>
                <div
                  id="collapsible-message-accordion-second-2"
                  class="message-body is-collapsible"
                  data-parent="accordion_second"
                  data-allow-multiple="true"
                >
                  <div class="message-body-content p-0 font-16">
                    <p class="mt-3">
                      Tuotenumero: 1072922<br />EAN: 6424002018964
                    </p>
                    <!-- <p class="mt-3 white-space-pre">{{product.product_information}}</p> -->
                  </div>
                </div>
              </article>

              <div class="divider"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FiskarsFooter />
  </section>
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import FiskarsNavbar from "@/components/FiskarsNavbar.vue";
import FiskarsFooter from "@/components/FiskarsFooter.vue";
import bulmaCollapsible from "@creativebulma/bulma-collapsible";
import selectProductMixin from "@/components/mixins/addProduct.js";

export default {
  name: "ProductDetailPage",
  components: {
    FiskarsNavbar,
    FiskarsFooter,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { selectProduct } = selectProductMixin();

    const product = reactive({});
    const collapsedFirstItem = ref(false);
    const collapsedSecondItem = ref(false);
    const collapsedThirdItem = ref(false);

    const initCollapsibleElements = () => {
      const bulmaCollapsibleElement =
        bulmaCollapsible.attach(".is-collapsible");
    };

    const clickCollapsibleItem = (itemPosition) => {
      [collapsedFirstItem, collapsedSecondItem, collapsedThirdItem][
        itemPosition
      ].value = ![collapsedFirstItem, collapsedSecondItem, collapsedThirdItem][
        itemPosition
      ].value;
    };

    onMounted(() => {
      initCollapsibleElements();
      Object.assign(
        product,
        store.getters.getProductById(route.params.productId)
      );
    });

    return {
      collapsedFirstItem,
      collapsedSecondItem,
      collapsedThirdItem,
      clickCollapsibleItem,
      product,
      selectProduct,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.product-family {
  color: $fiskars-grey;
}

.white-space-pre {
  white-space: pre;
}

.product-detail-button {
  width: 398px;
  max-width: 70%;
  @media screen and (max-width: 768px) {
    max-width: 300px;
  }
}

.collapse-header-text {
  color: $fiskars-black !important;
  font-family: helvetica-now-display-xbd;
}

.message-header {
  background-color: #ffffff;
}

.message-body {
  background-color: #ffffff;
}

.product-name {
  line-height: 1;
}

.product-3d-thumbnail img {
  background-color: #f3f2f2;
}
</style>
