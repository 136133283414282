<template>
  <asModalDialog
    ref="deleteItemModal"
    heading-text="Oletko varma?"
    ok-button-text="Kyllä"
    cancel-button-text="Ei"
  >
    Oletko varma, että haluat poistaa tuotteen ostoskorista?
  </asModalDialog>

  <div class="columns is-multiline cart-line-border-t">
    <template v-for="(item, index) in items" :key="index">
      <div
        class="column is-2 is-flex is-align-items-center is-justify-content-center"
      >
        <figure class="image zoom-thumbnail">
          <img :src="require(`../assets/img/${getFrontDesignColor(item)}`)" />
          <div
            class="thumbnail-front-text"
            v-html="formattedString(item['front_text'])"
          ></div>
        </figure>
      </div>
      <div
        class="column is-2 is-flex is-align-items-center is-justify-content-center"
      >
        <figure class="image zoom-thumbnail">
          <img :src="require(`../assets/img/${getBackDesignColor(item)}`)" />
          <div
            class="thumbnail-back-text"
            v-html="formattedString(item['back_text'])"
          ></div>
        </figure>
      </div>
      <div
        class="column is-2 is-flex is-align-items-center is-justify-content-center"
      >
        <h2 class="is-hidden-desktop is-hidden-tablet">
          {{ getProductName(item) }}
        </h2>
        <p class="is-hidden-mobile">{{ getProductName(item) }}</p>
      </div>

      <div class="column is-2 m-auto" v-if="isEditable">
        <div
          class="is-flex is-align-items-center is-justify-content-space-around"
        >
          <span class="is-clickable" @click="editItem(item)">
            <span class="pencil-icon"></span>
          </span>

          <span class="is-clickable" @click="deleteItem(item)">
            <span class="trash-icon"></span>
          </span>
        </div>
      </div>
      <div class="column is-2 m-auto">
        <div
          class="is-flex is-align-items-center is-justify-content-right is-justify-content-center-mobile"
        >
          <ProductAmount
            :isEditable="isEditable"
            v-model="item.amount"
            :itemId="item.id"
            @amountupdated="updateAmount"
          />
        </div>
      </div>

      <div class="column is-1 m-auto">
        <div
          class="is-flex is-align-items-center is-justify-content-right is-justify-content-center-mobile"
        >
          <span> á {{ getPricePerProduct(item) }} €</span>
        </div>
      </div>

      <div class="column is-1 m-auto">
        <div
          class="is-flex is-align-items-center is-justify-content-right is-justify-content-center-mobile"
        >
          <span> = {{ getTotalPricePerProduct(item) }} €</span>
        </div>
      </div>

      <div class="column is-12 cart-line-border-t py-0"></div>
    </template>

    <!-- End line items -->

    <!-- Start shipping cost -->
    <template v-if="showDeliveryRow">
      <div class="column is-two-fifth">
        <p class="delivery-text-title">Toimituskulut</p>
        <p v-if="sendingType == 'FREIGHT'" class="delivery-text-description">
          Toimitus rahtina yhteen osoitteeseen Suomessa
        </p>
        <p v-if="sendingType == 'MAILBOX'" class="delivery-text-description">
          Toimitus vastaanottajien postilaatikoihin
        </p>
      </div>
      <div class="column is-one-fifth is-invisible"></div>
      <div class="column is-one-fifth is-invisible"></div>
      <div class="column is-one-fifth" v-if="sendingType == 'MAILBOX'">
        <p class="is-invisible">x</p>
        <p class="delivery-text-price">5,90€ /kpl</p>
      </div>
      <div class="column is-one-fifth" v-if="sendingType == 'FREIGHT'">
        <p class="is-invisible">x</p>
        <p class="delivery-text-price" v-if="store.getters.getItemsTotalAmount <= 100">= 30,00 €</p>
        <p class="delivery-text-price" v-if="store.getters.getItemsTotalAmount > 100">= 0 €</p>
      </div>      
      <div class="column is-12 cart-line-border-t"></div>
    </template>
    <!-- End shipping cost -->

    <!-- Start total cost -->

    <div class="column is-one-fifth is-invisible"></div>

    <div class="column is-one-fifth is-invisible"></div>
    <div class="column is-one-fifth">
      <div
        class="is-flex is-align-items-center is-justify-content-right is-justify-content-center-mobile"
      >
        <span class="price-bold">{{ store.getters.getItemsTotalAmount }} kpl</span>
      </div>
    </div>
    <div class="column is-one-fifth is-invisible"></div>

    <div class="column is-one-fifth m-auto">
      <div
        class="is-flex is-align-items-center is-justify-content-left is-hidden-mobile"
      >
        <span class="price-bold">
          = {{ totalPriceWithDiscount }} €<br /><span v-if="showDeliveryRow" class="font-13"
            >Sisältäen veroja {{ vatAmount }} €</span
          >
        </span>
      </div>
      <div
        class="is-flex is-align-items-center is-justify-content-center is-flex-direction-column is-hidden-tablet"
      >
        <p class="price-bold" >= {{ totalPriceWithDiscount }} €</p>
        <p v-if="showDeliveryRow" class="font-13">Sisältäen veroja {{ vatAmount }} €</p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ProductAmount from "@/components/ProductAmount.vue";
import asModalDialog from "@/components/modals/asModalDialog.vue";
import cartMixin from "@/components/mixins/cartMixin";

export default {
  name: "PorductsTable",
  components: {
    ProductAmount,
    asModalDialog,
  },
  props: {
    isEditable: {
      type: Boolean,
      default: true,
    },
    showDeliveryRow: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const deleteItemModal = ref(null);
    const setLoader = (state) => store.commit("setSpinnerState", state);
    const sendingType = ref(store.getters.getSendingType);

    const {
      getProductName,
      getBackDesignColor,
      getFrontDesignColor,
      getPricePerProduct,
      getTotalPricePerProduct,
    } = cartMixin();

    const updateAmount = (itemId, amount) => {
      store.dispatch("updateItem", {
        id: itemId,
        amount: amount,
      });
    };

    const editItem = (item) => {
      router.push({
        name: "EditorPage",
        params: {
          orderUuid: store.getters.getOrderData.uuid,
          itemId: item.id,
          productId: item.product,
        },
      });
    };

    const deleteItem = (item) => {
      deleteItemModal.value
        .showModal()
        .then(() => {
          setLoader(true);
          store.dispatch("deleteItem", item.id).then(() => {
            store.dispatch("getOrder").then(() => {
              if (store.getters.getSavedItems.length > 0) {
                router.go(); // reload page
              } else {
                store.commit("setReseting", true);
                router.push({ name: "Landing" });
              }
            });
          });
        })
        .catch(() => {
          // User cancel item delete
        });
    };

    const totalPriceWithDiscount = computed(() => {
      let sendingCost = store.getters.getDeliveryCost / 100
      return (
        (sendingCost +
        parseFloat(store.getters.getTotalOrderPriceVat.replace(",", ".")) -
        parseFloat(store.getters.getVoucherValue) / 100 ) * 1.255
      )
        .toFixed(2)
        .replace(".", ",");
    });

    const vatAmount = computed(() => {
      let sendingCost = store.getters.getDeliveryCost / 100
      return (
        (sendingCost +
          parseFloat(store.getters.getTotalOrderPriceVat.replace(",", ".")) -
          parseFloat(store.getters.getVoucherValue) / 100) *
        0.255
      )
        .toFixed(2)
        .replace(".", ",");
    });

    const formattedString = (inputString) => {
      return inputString.replace(/\n/g, "<br>");
    };

    return {
      totalPriceWithDiscount,
      getPricePerProduct,
      getTotalPricePerProduct,
      getProductName,
      getBackDesignColor,
      getFrontDesignColor,
      editItem,
      deleteItem,
      updateAmount,
      deleteItemModal,
      store,
      items: computed(() => store.getters.getSavedItems),
      vatAmount,
      formattedString,
      sendingType,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";

.cart-line-border-t {
  border-top: 2px solid #d0d3d4;
}

.d-icon {
  background: url("../assets/icons/icon_gift.svg");
  height: 22px;
  width: 24px;
  display: block;
}
.pencil-icon {
  background: url("../assets/icons/icon_edit.svg");
  height: 24px;
  width: 24px;
  display: block;
}
.trash-icon {
  background: url("../assets/icons/icon_trash.svg");
  height: 24px;
  width: 24px;
  display: block;
}

.thumbnail-front-text {
  font-family: helvetica-now-text-medium;
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 9px;
  color: white;
  line-height: 10px;

  @media screen and (max-width: 1408px) {
    font-size: 7px;
    line-height: 9px;
  }
  @media screen and (max-width: 1408px) {
    font-size: 6px;
    line-height: 7px;
  }

  @media screen and (max-width: 769px) {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    line-height: 24px;
  }
}

.thumbnail-back-text {
  font-family: helvetica-now-text-medium;
  position: absolute;
  height: 100%;
  top: 0;
  left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 9px;
  color: white;
  right: 10%;
  line-height: 10px;
  @media screen and (max-width: 1408px) {
    font-size: 7px;
    line-height: 9px;
  }
  @media screen and (max-width: 1408px) {
    font-size: 6px;
    line-height: 7px;
  }

  @media screen and (max-width: 769px) {
    position: absolute;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 8%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    line-height: 24px;
  }
}

.zoom-thumbnail {
  transition: transform 0.2s;
}

.zoom-thumbnail:hover {
  z-index: 20;
  transform: scale(2.5);
}

.delivery-text-title {
  font-size: 20px;
  font-family: helvetica-now-display-xbd;
}
.delivery-text-description {
  font-size: 16px;
}
.delivery-text-price {
  font-size: 16px;
}
.price-bold {
  font-family: helvetica-now-display-xbd;
}
</style>
