<template>
  <section class="hero customer-service-page is-fullheight">
    <FiskarsNavbar />
    <div class="hero-body">
      <div class="container">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <h2 class="mb-5">Ota yhteyttä</h2>
            <p class="mt-2">
              Fiskars Groupin B2B-tiimi toimittaa yrityslahjoja, tuottaa myynnin edistämiseen tuotteita, mahdollistaa personoidut ratkaisut sekä varustaa horeca-puolen ja matkailualan toimipisteitä. <br><br>Ota meihin yhteyttä ja palvelemme sinua mahdollisimman pian.
            </p>
            

            <p class="my-5 helv-neue-font has-text-weight-semibold">FISKARS GROUP YRITYSMYYNNIN ASIAKASPALVELU:</p>
            <a href="mailto:yritysmyynti@fiskars.com">yritysmyynti@fiskars.com</a>
            <p class="my-5 helv-neue-font">
               Fiskars Group Yritysmyynti <br> Keilaniementie 10 <br>P.O.Box 91 <br>02150 Espoo<br> Tel. +358 20 439 10
            </p>            

            <div class="divider" id="faq"></div>            

            <h2 class="fiskars-font-bold mt-5 pt-5">Usein kysytyt kysymykset</h2>
            <h4 class="mt-4">Kuinka paljon palvelu maksaa?</h4>
            <p>Saksien hinta alkaen à 23,82 alv 0 %. Laskun loppusummaan lisätään voimassa oleva arvonlisävero. Hintaan kuuluu valitsemasi tuote itse suunnitellussa pakkauksessa. Sen lisäksi tilaukselle lasketaan toimituskulut valitsemasi toimitustavan mukaan.</p>

            <h4 class="mt-3">Montako erilaista pakkausta voin tilata samalla tilauksella?</h4>
            <p>Minimitilausmäärä on 50 tuotetta. Paketin tekstit on mahdollista toimittaa myös excel-tiedostona, jolloin tekstit on helpompi syöttää eri paketeille.</p>  

            <h4 class="mt-3">Mitkä maksutavat ovat käytössä?</h4>
            <p>Yritystilauksissa maksutapana on lasku tai ennakkolasku. Maksuehto on laskulla 14 päivää netto.</p>

            <h4 class="mt-3">Mikä on toimitusaika?</h4>
            <p>Pakkaukset painetaan noin kolme viikkoa tilauksen vahvistamisesta ja luovutetaan sitten seuraavana arkipäivänä kuljetusyhtiön toimitettavaksi.</p>
            
            <h4 class="mt-3">Minne ja miten tilaus toimitetaan?</h4>
            <p>Palvelussa tehdyt tilaukset toimitetaan asiakkaan antamaan toimitusosoitteeseen (toimitusalue on Manner-Suomi).</p>
            <p>Kuljetusyhtiö: Posti Oyj</p>   
            
            <h4 class="mt-3">Miten voin seurata tilaustani?</h4>
            <p>Saat tiedon sähköpostiisi, kun tilauksesi lähtee ja tässä viestissä on mukana rahdin seurantalinkki</p>  
            
            <h4 class="mt-3">Kuinka pitkä teksti voi olla?</h4>
            <p>Pakkauksen etupuolelle 44 merkkiä ja enintään neljä riviä tekstiä. Pakkauksen takaosaan 140 merkkiä ja enintään kuusi riviä tekstiä. Määrät sisältävät erikoismerkit, rivinvaihdot ja välilyönnit.</p>
            
            <h4 class="mt-3">Voinko kirjoittaa kääreeseen emojia / hymiöitä tai erikoismerkkejä?</h4>
            <p>Valitettavasti kääreen fontti ei tue emoticoneja, emojia tai symboleita. Mutta voit toki käyttää mielesi mukaan ”tekstihymiöitä” kuten :-) tai >3. Myös "normaalit erikoismerkit" kuten ! @ $ | > &lt; * # toimivat.</p>
            
            <h4 class="mt-3">Voivatko tekstit olla mitä tahansa?</h4>
            <p>Palvelumme on tarkoitettu ilahduttamaan, kiittämään, yllättämään positiivisesti ja kannustamaan. Emme hyväksy palvelun kautta lähetettävissä teksteissä kiroilua, rasistista kielenkäyttöä tai muuta hyvän tavan vastaista tai Fiskarsin eettisten periaatteiden vastaista kirjoitusta.</p>
            <p>Fiskarsilla on oikeus estää palvelun sääntöjen vastaisen viestin lähettäminen. Fiskarsilla on oikeus perustellusta syystä olla hyväksymättä tilausta tai osaa siitä tilauksen vastaanottovahvistuksen lähettämisestä huolimatta. Tutustu palvelumme sopimus- ja toimitusehtoihin</p>   
            
            
            <h4 class="mt-3">Miten lisään useamman toimitusosoitteen</h4>
            <p>Asiakaspalvelumme hoitaa tilauksen jatkokäsittelyn excel-tiedoston avulla. Lähetämme sinulle tilausvahvistuksen liitteenä tiedoston, jossa on ohjeet tekstien ja osoitteiden kirjaamiselle. Tilauksesi käsitellään valmiiksi, kun olemme saaneet sinulta taulukon takaisin.</p>   
            
            <h4 class="mt-3">Voinko lisätä yrityksen logon kääreeseen?</h4>
            <p>Valitettavasti kääreen fontti ei tue symboleita.</p>  
            
            <h4 class="mt-3">Voiko samalle tilaukselle valita pakkauskääreitä useammassa värissä?</h4>
            <p>Kyllä, valittavanasi on neljä eriväristä pakkauskäärettä</p>
            
            <h4 class="mt-3">Voiko tilauksella olla erilaisia viestejä?</h4>
            <p>Kyllä, voit personoida vaikka jokaisen kääreen haluamallasi tavalla, esim. vastaanottajan nimellä. Paketin tekstit toimitetaan silloin excel-tiedostona, jolloin tekstit on helpompi syöttää eri paketeille.</p>
            
            <h4 class="mt-3">Miksi tilaustani ei hyväksytty?</h4>
            <p>Tarkista, että kaikki * merkityt pakolliset tiedot on täytetty tilaukselle.</p>
            <p>Saksien minimitilausmäärä on 50 kappaletta joko yhteen osoitteeseen tai useampaan osoitteeseen toimitettuna.</p>
            <p>Tekstin tulee olla palvelun sääntöjen mukainen, kts. kohta Voivatko tekstit olla mitä tahansa.</p>
            <p>Mikäli sinulla on kysyttävää, voit olla yhteydessä <a href="mailto:yritysmyynti@fiskars.com">yritysmyynti@fiskars.com</a></p>                        
            
            <h4 class="mt-3">Miten toimin, jos saamani tuote on viallinen tai väärä?</h4>
            <p>Fiskars tuotteilla on normaali raaka-aine- ja valmistusvirhetakuu. Käyttövirheitä emme korvaa. Sinun tulee tarkistaa saamasi tuotteet viipymättä sen jälkeen, kun olet vastaanottanut tilauksesi. Viallisista tuotteista tulee ilmoittaa meille kahden viikon kuluessa paketin vastaanottamisesta. Ota yhteyttä asiakaspalveluumme yritysmyynti@fiskars.com ja ilmoita tilausnumerosi, joka löytyy sähköpostitse saamassasi tilausvahvistuksessa. Huomaathan, että viallista tuotetta palauttaessasi vaihtoa varten tuotteiden saatavuus ei välttämättä ole taattu ja tällöin palautus käsitellään hyvityksenä. Personoidun kääreen oikeinkirjoituksesta vastaa tilaaja.</p>
            
            <h4 class="mt-3">Miten saan Excel-tiedoston useaa Postin toimitusosoitetta varten?</h4>
            <p>Asiakaspalvelumme hoitaa tilauksen jatkokäsittelyn excel-tiedoston avulla. Lähetämme sinulle tilausvahvistuksen liitteenä tiedoston, jossa on ohjeet tekstien ja osoitteiden kirjaamiselle. Tilauksesi käsitellään valmiiksi, kun olemme saaneet sinulta taulukon takaisin.</p>               
          </div>
        </div>
      </div>
    </div>

    <FiskarsFooter />
  </section>
</template>

<script>
import FiskarsNavbar from "@/components/FiskarsNavbar.vue";
import FiskarsFooter from "@/components/FiskarsFooter.vue";

export default {
  name: "CustomerService",
  props: {},
  components: {
    FiskarsNavbar,
    FiskarsFooter,
  },
  setup(props) {
    return {};
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.cutomer-service-button {
  .helv-neue-font {
    font-family: 'helvetica-now-text-medium';
  }
}
</style>
