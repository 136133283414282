<template>
    <div id="subnavbar-black-bar">
      <div class="columns ml-0 mr-0">
        <div class="column has-text-centered">
          <p class="has-text-white">Juhlavuoden saksia on saatavilla rajoitettu erä.</p>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SubNavbar'
}
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
#subnavbar-black-bar {
  background-color: #749D74;
  padding-top: 75px;
  padding-bottom: 8px;
  width: 100%;
  max-height: 160px;
}

@media screen and (max-width: 768px) {
  #subnavbar-black-bar {
    padding-top: 65px;
  }
}
</style>
