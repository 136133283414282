import { createStore } from "vuex"
import VuexPersistence from "vuex-persist"
import doAsync from "./async-util"
import * as types from "./mutation-types"
import localforage from "localforage"

const local_store = localforage.createInstance({
  name: "fiskars-webshop",
});

const vuexPersist = new VuexPersistence({
  storage: local_store,
  reducer: (state) => {
    return JSON.parse(JSON.stringify(state));
  },
  asyncStorage: true,
});

let state_dict = {
  token: '',
  order: {
    items: []
  },
  contact: {},
  products: [], // service configured products
  spinnerActive: false,
  reseting: false,
  selectedProduct: undefined,
  stripeError: false,
  voucherValue: "0",
  cookieNotificationShow: true,
  googleCookieEnabled: false,
  sendingType: 'FREIGHT', // FREIGHT, MAILBOX
}

let store_config = {
  state() {
    return state_dict
  },
  mutations: {
    setAuth(state, token) {
      state.token = token
    },      
    partialReset(state) {
      let token = state.token
      Object.assign(state, state_dict)
      state.token  = token
    },
    setSpinnerState(state, spinnerState) {
      state.spinnerActive = spinnerState
    },
    setProducts(state, data) {
      state.products = data
    },
    setSendingType(state, data) {
      state.sendingType = data
    },
    setOrder(state, data) {
      state.order = data
    },
    setContact(state, data) {
      state.contact = data
    },
    setSelectedProduct(state, productId) {
      state.selectedProduct = productId
    },
    setStripeError(state, stripeError) {
      state.stripeError = stripeError
    },
    setReseting(state, reset) {
      state.reseting = reset
    },
    setVoucherValue(state, voucherData) {
      state.voucherValue = voucherData
    },
    setCookieNotificationShow(state, value) {
      state.cookieNotificationShow = value
    },
    setGoogleCookieEnabled(state, value) {
      state.googleCookieEnabled = value
    },
    resetStore(state) {
      state_dict.cookieNotificationShow = state.cookieNotificationShow // keep cookie value
      state_dict.googleCookieEnabled = state.googleCookieEnabled // keep google cookie value
      Object.assign(state, state_dict)
    },
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getOrderData(state) {
      return state.order
    },
    getContactData(state) {
      return state.contact
    },
    getProducts(state) {
      return state.products
    },
    getSendingType(state) {
      return state.sendingType
    },
    getReseting(state) {
      return state.reseting
    },
    getProductById: (state) => (productId) => {
      return state.products.find(prod => prod.id == productId)
    },
    getItemById: (state) => (itemId) => {
      return state.order.items.find(item => item.id == itemId)
    },
    getSelectedProduct(state) {
      return state.selectedProduct
    },
    getItemsTotalAmount: (state, getters) => {
      let total = 0
      if (state.order == null || state.order == {}) {
        return 0
      }
      getters.getSavedItems.forEach(function(item) {
        total += item.amount
      })
      return total
    },
    getSavedItems: (state) => {
      return state.order.items.filter(el => el.draft === false)
    },
    getTotalOrderPriceVat: (state, getters) => {
      let total = 0
      if (getters.getSavedItems == undefined) {
        return total
      }
      getters.getSavedItems.forEach(item => {
        total += state.products.find(prod => prod.id == item.product).price * item.amount
      })
      return (total / 100).toFixed(2).replace('.', ',')
    },
    getDeliveryCost: (state, getters) => {
      // Return in cents without vat applied
      let total = 0
      if (getters.getSendingType == 'MAILBOX') {
        total = getters.getItemsTotalAmount * 590
      } else {
        // FREIGHT, if greater than 100 items free if not 30€
        total = getters.getItemsTotalAmount > 100 ? 0 : 3000
      }
      return total
    },
    getStripeError(state) {
      return state.stripeError
    },
    getVoucherValue(state) {
      let voucherDiscount = "0"
      if (state.voucherValue.amount_off != null) {
        voucherDiscount = state.voucherValue.amount_off
          .toFixed(2)
          .replace(".", ",");
      } else if (state.voucherValue.percent_off != null) {
        // parsing % to absolute price in cents according total order amount
        voucherDiscount = (
          store.getters.getTotalOrderPriceVat.replace(",", ".") *
          1 *
          parseFloat(state.voucherValue.percent_off)
        )
          .toFixed(2)
          .replace(".", ",");
      }

      return voucherDiscount
    },
    getCookieShowNotification(state) {
      return state.cookieNotificationShow
    },
    getGoogleCookieEnabled(state) {
      return state.googleCookieEnabled
    }
  },
  actions: {
    getAuthenticate(store, force=false) {
      if (force || store.state.token != '') {
        return
      }
      return doAsync(store, {
        url: `login/`,
        mutationTypes: types.GET_AUTHENTICATE,
        method: 'post',
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setAuth', res.data.token)
        }
        return res
      })
    },
    getProducts(store) {
      return doAsync(store, {
        url: `products/`,
        mutationTypes: types.GET_PRODUCTS,
        method: 'get',
      }).then(() => {
        store.commit('setProducts', store.state.getProductsData.results)
      })
    },
    postOrder(store) {
      return doAsync(store, {
        url: `order/`,
        mutationTypes: types.POST_ORDER,
        method: 'post',
      }).then(() => {
        store.commit('setOrder', store.state.postOrderData)
      })
    },    
    getOrder(store, orderNumberOverride='') {
      let order_number
      if (orderNumberOverride)
        order_number = orderNumberOverride
      else
        order_number = store.state.order.order_number

      return doAsync(store, {
        url: `order/${order_number}/`,
        mutationTypes: types.GET_ORDER,
        method: 'get',
      }).then(() => {
        store.commit('setOrder', store.state.getOrderData)
      })
    },
    partialUpdateOrder(store, orderData) {
      orderData['order_number'] = store.state.order.order_number
      return doAsync(store, {
        url: `order/patch/`,
        mutationTypes: types.PARTIAL_UPDATE_ORDER,
        method: 'patch',
        data: orderData,
      }).then(() => {
        store.commit('setOrder', store.state.getOrderData)
      })
    },
    postItem(store, data) {
      return doAsync(store, {
        url: `item/`,
        mutationTypes: types.POST_ITEM,
        method: 'post',
        data: data,
      })
    },
    updateItem(store, data) {
      return doAsync(store, {
        url: `item/${data['id']}/`,
        mutationTypes: types.UPDATE_ITEM,
        method: 'patch',
        data: data,
      })
    },
    deleteItem(store, item_id) {
      return doAsync(store, {
        url: `item/${item_id}/`,
        mutationTypes: types.DELETE_ITEM,
        method: 'delete',
      })
    },     
    postContact(store, formData) {
      formData['order'] = store.state.order.order_number
      return doAsync(store, {
        url: 'contact/',
        mutationTypes: types.POST_CONTACT,
        method: 'post',
        data: formData,
      }).then(() => {
        store.commit('setContact', store.state.postContactData)
      })
    },
    putContact(store, formData) {
      formData['order'] = store.state.order.order_number
      return doAsync(store, {
        url: `contact/${store.state.contact.id}/`,
        mutationTypes: types.PUT_CONTACT,
        method: 'put',
        data: formData,
      }).then(() => {
        store.commit('setContact', store.state.putContactData)
      })
    },  
    createStripeSession(store) {
      return doAsync(store, {
        url: `order/${store.state.order.order_number}/create_stripe_session/`,
        mutationTypes: types.STRIPE_SESSION,
        method: 'post',
      })
    },
    addVoucher(store, data) {
      return doAsync(store, {
        url: `voucher-discount/${data['code']}/`,
        mutationTypes: types.ADD_VOUCHER,
      })
    },
  },
  plugins: [vuexPersist.plugin],
}

/*
 * For each async mutation, dynamically add the three mutations:
 * SUCCESS - write the response to the store using the `stateKey` property
 * PENDING - set the `loadingKey` property in the store to true
 * FAILURE - Set `loadingKey` to false, and `errorCode` - for example 401, 404...
 *
 * stateKey, errorCode and loadingKey are prepended with the name of the action,
 * for example an action getData would lead to:
 *
 * mutations:
 *   GET_DATA_SUCCESS,
 *   GET_DATA_PENDING,
 *   GET_DATA_FAILURE
 *
 * state:
 *   getDataLoadingKey,
 *   getDataStateKey,
 *   getDataErrorCode
 *
 * For all mutations following defaults are applied:
 *   getDataLoadingKey = false
 *   getDataStateKey = null
 *   getDataStatusCode = null
 *   getDataErrorCode = null
 */
Object.keys(types).forEach((type) => {
  // add the initial state
  state_dict[types[type].loadingKey] = false
  state_dict[types[type].stateKey] = null
  state_dict[types[type].statusCode] = null
  state_dict[types[type].FAILURE] = null

  // add the mutation
  store_config['mutations'][types[type].BASE] = (state, payload) => {
    switch (payload.type) {
      case types[type].PENDING:
        state[types[type].loadingKey] = payload.value
        return state[types[type].loadingKey]

      case types[type].SUCCESS:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = null
        state[types[type].stateKey] = payload.data
        return state[types[type].stateKey]

      case types[type].FAILURE:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = payload.error
        return state[types[type].FAILURE]
    }
  }
})

const store = createStore(store_config)

export default store


