<template>
  <section class="hero landing-wrapper">
    <FiskarsNavbar />
    <div class="hero-body p-0">
      <asModalDialog
        ref="cookieNotification"
        ok-button-text="Vain välttämättömät evästeet"
        :hiddenX="true"
        cancelButtonText="Hyväksyn kaikki evästeet"
      >
        <p>
          Evästeiden avulla parannamme käyttökokemustasi ja kehitämme
          palveluamme.
        </p>
        <p class="my-3">
          Käytämme sekä omia että kolmansien osapuolien evästeitä. Lue lisää
          käyttämistämme <a @click="openCookiePolicyPage">evästeistä.</a>
        </p>
        <p>
          Voit hyväksyä evästeet tai muuttaa evästeasetuksia, miten niiden
          avulla kerättyä tietoa on mahdollista käyttää käytetään.
        </p>
      </asModalDialog>
      <div class="is-flex green-bg-desktop is-relative is-hidden-touch">
        <div class="container">
          <div
            class="columns mt-0 is-flex is-flex-direction-column is-justify-content-space-around h-100"
            id="columns-ref"
          >
            <div class="column is-6">
              <figure class="image quality-cstm-img">
                <img
                  src="../assets/img/Fiskars_375_symbol_primary_white@2x.png"
                />
              </figure>
              <h1 class="has-text-left mb-5 has-text-white">
                Kiitä henkilöstöä & asiakkaita ajattomalla lahjalla
              </h1>
              <p class="has-text-left font-16 has-text-white">
                Ihanimmat lahjat ilahduttavat koko vuoden. Aikaa ja käyttöä kestävä lahja on täydellinen tapa kiittää henkilöstöä ja yhteistyökumppaneita hyvästä työpanoksesta, tehostaa sisäistä markkinointia sekä edistää myyntiä. Voit halutessasi lähettää personoidun lahjapakkauksen suoraan saajalle. <br><br>Lahjan toimitusaika on 10–14 arkipäivää. Minimitilausmäärä 50 kappaletta.
              </p>
            </div>
          </div>
        </div>
        <div class="img-container">
          <img
            src="../assets/img/landing_right_picture.png"
            class="fiskar-img-1"
            id="img-ref"
          />
          <img
            src="../assets/img/FIS_Classic-sakset_valmistetaan_ja_testataan_kasin_1_FIN@2x.png"
            class="flag-img"
          />
          <img
            src="../assets/img/Takuu_25_vuotta_FI_1x1@2x.png"
            class="anniversary-img"
          />          
        </div>
      </div>

      <div class="container cstm-container">
        <div class="columns is-multiline mt-4 mb-4 mr-0 ml-0 mb-0 is-hidden-touch">
          <div class="column is-12">
            <h3 class="has-text-centered mt-4 mb-4">
              Värivaihtoehdot lahjapakkauksiin
            </h3>
          </div>
          <div class="column is-3">
            <figure class="image">
              <img src="../assets/img/scissors_box1.jpg" />
            </figure>
          </div>
          <div class="column is-3">
            <figure class="image">
              <img src="../assets/img/scissors_box2.jpg" />
            </figure>
          </div>
          <div class="column is-3">
            <figure class="image">
              <img src="../assets/img/scissors_box3.jpg" />
            </figure>
          </div>
          <div class="column is-3">
            <figure class="image">
              <img src="../assets/img/scissors_box4.jpg" />
            </figure>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="columns mr-0 ml-0 mb-0 is-hidden-desktop">
          <div class="column is-mobile is-12 pl-0 pr-0 pb-0">
            <img
              src="../assets/img/landing_right_picture.png"
              class="fiskar-img-1"
            />
          </div>
        </div>
        <div class="columns mb-0 ml-0 mr-0 is-hidden-desktop">
          <div class="green-bg-mobile column is-mobile is-12 mt-0">
            <h1 class="has-text-left mb-3 has-text-white">
              Kiitä henkilöstöä & asiakkaita ajattomalla lahjalla
            </h1>
            <p class="has-text-left has-text-white">
              Ihanimmat lahjat ilahduttavat koko vuoden. Aikaa ja käyttöä kestävä lahja on täydellinen tapa kiittää henkilöstöä ja yhteistyökumppaneita hyvästä työpanoksesta, tehostaa sisäistä markkinointia sekä edistää myyntiä. Voit halutessasi lähettää personoidun lahjapakkauksen suoraan saajalle. <br><br>Lahjan toimitusaika on 10–14 arkipäivää. Minimitilausmäärä 50 kappaletta.
            </p>
          </div>
        </div>
        <div class="columns is-multiline mt-6 mr-0 ml-0 p20 mb-5">
          <div class="column is-8 is-offset-2 padding-zero">
            <h3 class="has-text-centered has-text-left-mobile">
              Laadukas yrityslahja yksilöidyllä tervehdyksellä.
            </h3>
            <p class="has-text-centered mt-4 has-text-left-mobile">
              Pakkauksen etu- ja takapuolelle painetaan haluamasi tekstit. Hinta sisältää lahjapakkauksen.<br> Minimitilausmäärä on 50 kappaletta. Toimitus joko suoraan vastaanottajien postilaatikkoon tai isommassa erässä rahtina haluttuun osoitteeseen Suomessa.
            </p>
          </div>
          <div
            class="column is-4-widescreen is-offset-4-widescreen is-4-desktop is-offset-4-desktop is-6-tablet is-offset-3-tablet is-flex padding-zero"
            v-for="(product, index) in products"
            :key="index"
          >
            <ProductCard :product="product" />
          </div>
        </div>
      </div>

      <div class="is-flex bg-orange is-relative is-hidden-touch mt-5">
        <div class="img-container-2">
          <img
            src="../assets/img/Fiskars_Factory_images_Billnas_2019_01.png"
            class="fiskar-img-1"
            id="img-ref-2"
          />
        </div>
        <div class="container">
          <div
            class="columns mt-0 is-flex is-flex-direction-column is-justify-content-space-around h-100"
            id="columns-ref-2"
          >
            <div class="column is-6 is-offset-6">
              <h3 class="has-text-left pl-5 mb-3">Classic-saksien matkassa</h3>
              <p class="has-text-left pl-5 mb-4">
                Fiskarsin oranssikahvaisten saksien tarina alkoi vuonna 1967 Fiskarsin kylästä. Ne olivat ensimmäiset kuluttajille suunnatut sakset, joissa yhdistettiin ensimmäistä kertaa muovinen kahva ruostumattomasta teräksestä valmistettuun teräosaan.
              </p>
              <p class="has-text-left pl-5 mb-4">
                Fiskarsin sakset tunnistaa niiden kahvoista, joiden oranssi väri valittiin sattumalta: prototyyppien valmistuksessa koneeseen oli jäänyt oranssia väriä, jonka koneenkäyttäjä päätti kuitenkin käyttää loppuun. Sisäisessä äänestyksessä oranssi vei voiton punaisesta, mustasta ja vihreästä vaihtoehdosta.
              </p>
              <p class="has-text-left pl-5 mb-2">
                Nykyään Fiskarsin saksia myydään maailmanlaajuisesti, ja niitä pidetään laadun, innovaation ja toimivuuden symbolina. Yli miljardi myytyä kappaletta todistaa niiden ajattomasta suunnittelusta ja luotettavuudesta.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="container cstm-container">
        <div class="columns is-multiline mt-4 mb-4 mr-0 ml-0">
          <div class="column is-12 has-text-centered">
            <iframe
              class="video-iframe"
              src="https://www.youtube.com/embed/QUUvvVzu6CE"
            ></iframe>
          </div>
          <div class="column is-12 has-text-centered">
            <button class="button fiskars-button" @click="goToProductDetail">
              Suunnittele lahjapakkaus
            </button>
          </div>
        </div>
      </div>
    </div>
    <FiskarsFooter />
  </section>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import FiskarsNavbar from "@/components/FiskarsNavbar.vue";
import FiskarsFooter from "@/components/FiskarsFooter.vue";
import ProductCard from "@/components/ProductCard.vue";
import asModalDialog from "@/components/modals/asModalDialog.vue";
import { useState } from "vue-gtag-next";

export default {
  name: "LandingPage",
  components: {
    FiskarsNavbar,
    FiskarsFooter,
    ProductCard,
    asModalDialog,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const setLoader = (state) => store.commit("setSpinnerState", state);
    const cookieNotification = ref(null);
    const { property } = useState();

    const resizeImg = () => {
      const cols = document.getElementById("columns-ref");
      const img = document.getElementById("img-ref");
      img.style.height = `${cols.offsetHeight}px`;
    };
    const resizeImg2 = () => {
      const cols = document.getElementById("columns-ref-2");
      const img = document.getElementById("img-ref-2");
      img.style.height = `${cols.offsetHeight}px`;
    };

    const openCookiePolicyPage = () => {
      router.push({
        name: "CookiePolicy",
      });
    };

    const goToProductDetail = () => {
      router.push({
        name: "ProductDetail",
        params: {
          productId: store.getters.getProducts[0].id,
        },
      });
    };    

    onMounted(() => {
      resizeImg();
      resizeImg2();
      window.addEventListener("resize", resizeImg);

      if (store.getters.getReseting) {
        store.commit("setReseting", false);
        store.commit("resetStore");
      }
      store.dispatch("getProducts");
      setLoader(false); // reset state

      // No cookie modal for the moment
      //
      //
      // if (store.getters.getCookieShowNotification) {
      //   cookieNotification.value
      //     .showModal()
      //     .then(() => {
      //       // Only essential cookies accepted

      //       // no need to cancel google analytics since google ID is not applied to library
      //       // https://matteo-gabriele.gitbook.io/vue-gtag/v/next/bootstrap-options

      //       store.commit("setCookieNotificationShow", false);
      //       store.commit("setGoogleCookieEnabled", false);
      //     })
      //     .catch(() => {
      //       // Accept all cookies so we apply google ID analytics
      //       store.commit("setCookieNotificationShow", false);
      //       store.commit("setGoogleCookieEnabled", true);
      //       property.value = {
      //         id: process.env.VUE_APP_GA_ID,
      //       };
      //     });
      // } else {
      //   // if user exists and enters app again we apply analytics again if enabled
      //   if (store.getters.getGoogleCookieEnabled) {
      //     property.value = {
      //       id: process.env.VUE_APP_GA_ID,
      //     };
      //   }
      // }
    });

    onUnmounted(() => {
      window.removeEventListener("resize", resizeImg);
    });

    return {
      products: computed(() => store.getters.getProducts),
      cookieNotification,
      openCookiePolicyPage,
      goToProductDetail,
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.landing-wrapper {
  .cstm-container {
    min-height: auto;
  }
  .green-bg-desktop {
    background-color: $fiskars-dark-green;
  }
  .green-bg {
    background-color: $fiskars-dark-green;
    width: 50%;
    height: 602px;
    padding: 20px;
    h1 {
      line-height: 1;
    }
  }
  .green-bg-mobile {
    background-color: $fiskars-dark-green;
    margin-top: 12px;
    padding-top: 45px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    p {
      font-size: 14px;
    }
  }
  .quality-cstm-img {
    width: 30%;
  }
  .green-right-img-bg {
    width: 50%;
    height: 602px;
  }
  .fiskar-img-1 {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .bg-orange {
    background-color: $fiskars-orange;
  }
  .flag-img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 157px;
    height: 157px;
  }
  .anniversary-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 185px;
    height: 185px;    
  }
}
.container {
  min-height: 70vh;
}

.img-container {
  position: absolute;
  left: 50%;
  width: 50%;
  img {
    width: 100%;
  }
}

.img-container-2 {
  position: absolute;
  right: 50%;
  width: 50%;
  img {
    width: 100%;
  }
}

.h-100 {
  height: 100%;
}

.p20 {
  @media screen and (max-width: 768px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.padding-zero {
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.video-iframe {
  width:560px;
  height:315px;
  max-width: 100%;
}
</style>
