<template>
  <section class="hero cart-page is-fullheight">
    <FiskarsNavbar />
    <div class="hero-body pt-5">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <h2>Tilauksesi</h2>
          </div>
        </div>

        <ProductsTable :isEditable="false"></ProductsTable>

        <!-- Start Contact form -->

        <div class="columns">
          <div class="column">
            <h2>Asiakastiedot</h2>
            <p class="my-4">
              Täytä tähän tilauksen maksajan tiedot. Lähetämme
              tilausvahvistuksen sähköpostitse. Jos et jo ole Fiskarsin
              yritysasiakas, rekisteröidy asiakkaaksemme.
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column is-4">
            <p class="mb-1">Nimi *</p>
            <input class="input" type="text" v-model="contact.buyer_name" />
            <p v-if="v$.buyer_name.$error" class="help is-danger">
              Tämä kenttä on pakollinen
            </p>
          </div>

          <div class="column is-4">
            <p class="mb-1">Sähköpostiosoite *</p>
            <input class="input" type="text" v-model="contact.cust_email" />
            <p v-if="v$.cust_email.$error" class="help is-danger">
              Tämä kenttä on pakollinen
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column is-4">
            <p class="mb-1">Company *</p>
            <input class="input" type="text" v-model="contact.bill_company" />
            <p v-if="v$.bill_company.$error" class="help is-danger">
              Tämä kenttä on pakollinen
            </p>
          </div>

          <div class="column is-4">
            <p class="mb-1">Y-tunnus *</p>
            <input class="input" type="text" v-model="contact.bill_ytunnus" />
            <p v-if="v$.bill_ytunnus.$error" class="help is-danger">
              Tämä kenttä on pakollinen
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <label class="checkbox">
              <input type="checkbox" v-model="contact.already_fiskar_customer" />
              Olen Fiskarsin yritysasiakas
            </label>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <label class="checkbox">
              <input type="checkbox" v-model="contact.new_fiskar_customer" />
              Haluan rekisteröityä Fiskars yritysasiakkaaksi
            </label>
          </div>
        </div>        

        <div class="columns">
          <div class="column">
            <h2>Toimitustiedot</h2>
            <p class="my-4">Täytä tähän lähetyksen vastaanottajan tiedot.</p>
          </div>
        </div>

        <div class="columns">
          <div class="column is-4">
            <p class="mb-1">Nimi *</p>
            <input class="input" type="text" v-model="contact.recv_name" />
            <p v-if="v$.recv_name.$error" class="help is-danger">
              Tämä kenttä on pakollinen
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column is-4">
            <p class="mb-1">Katu *</p>
            <input class="input" type="text" v-model="contact.recv_street" />
            <p v-if="v$.recv_street.$error" class="help is-danger">
              Tämä kenttä on pakollinen
            </p>
          </div>

          <div class="column is-4">
            <p class="mb-1">Postinumero *</p>
            <input class="input" type="text" v-model="contact.recv_zip" />
            <p v-if="v$.recv_zip.$error" class="help is-danger">
              Tämä kenttä on pakollinen
            </p>
          </div>
        </div>

        <div class="columns">
          <div class="column is-4">
            <p class="mb-1">Kaupunki *</p>
            <input class="input" type="text" v-model="contact.recv_town" />
            <p v-if="v$.recv_town.$error" class="help is-danger">
              Tämä kenttä on pakollinen
            </p>
          </div>

          <div class="column is-4">
            <p class="mb-1">Puhelinnumero toimitusilmoitusta varten *</p>
            <input class="input" type="text" v-model="contact.recv_phone" />
            <p v-if="v$.recv_phone.$error" class="help is-danger">
              Tämä kenttä on pakollinen
            </p>
          </div>
        </div>

        <div class="columns mb-5">
          <div class="column pt-0">
            <p>* Pakollinen tieto</p>
          </div>
        </div>
        <div class="columns mb-5">
          <div class="column">
            <label class="checkbox">
              <input type="checkbox" v-model="terms" />
              Hyväksyn
              <a href="https://fiskars-webshop-prod.s3.eu-north-1.amazonaws.com/public/Fiskars+B2B+yleiset+toimitusehdot+2024.pdf" target="_blank">kaupan ehdot</a
              >
            </label>
            <p v-if="termsError" class="help is-danger">
              Myynti ja toimitusehdot on hyväksyttävä jatkaaksesi.
            </p>
          </div>
        </div>

        <div class="columns" v-if="sendingType == 'FREIGHT'">
          <div class="column">
            <p class="fiskars-font-bold">Toimitustapa</p>
            <p>Toimitus rahtina yhteen osoitteeseen Suomessa</p>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <p class="fiskars-font-bold">Maksutapa</p>
            <p>Lasku</p>
          </div>
        </div>
        <!-- End Contact form -->

        <div class="columns">
          <div class="column is-hidden-mobile">
            <button
              class="button fiskars-button go-back-button"
              @click="goBack"
            >
              Palaa takaisin
            </button>
            <button
              class="button ml-3 fiskars-button go-pay-button"
              :enabled="stripeLoaded"
              @click="submitContact"
            >
              Lähetä tilaus
            </button>
          </div>
          <div class="column is-flex is-flex-direction-column is-hidden-tablet">
            <button
              class="button fiskars-button go-pay-button"
              :enabled="stripeLoaded"
              @click="submitContact"
            >
              Lähetä tilaus
            </button>
            <button
              class="button mt-3 fiskars-button go-back-button"
              @click="goBack"
            >
              Palaa takaisin
            </button>
          </div>
        </div>
      </div>
    </div>
    <FiskarsFooter />
  </section>
</template>

<script>
import FiskarsNavbar from "@/components/FiskarsNavbar.vue";
import FiskarsFooter from "@/components/FiskarsFooter.vue";
import ProductsTable from "@/components/ProductsTable";
import useVuelidate from "@vuelidate/core";
import { email, required, requiredIf } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { ref, computed, onBeforeMount, reactive } from "vue";

export default {
  name: "ContactPage",
  components: {
    FiskarsNavbar,
    FiskarsFooter,
    ProductsTable
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const terms = ref(false);
    const termsError = ref(false);
    const stripeLoaded = ref(false);
    const sendingType = ref(store.getters.getSendingType);
    const new_fiskar_customer = ref(false)

    let contactData = {
      buyer_name: "",
      cust_email: "",
      bill_company: "",
      bill_ytunnus: "",
      recv_name: "",
      recv_street: "",
      recv_zip: "",
      recv_town: "",
      recv_phone: "",
      new_fiskar_customer: false,
      already_fiskar_customer: false
    };

    if (store.getters.getContactData.id) {
      contactData = store.getters.getContactData;
    }

    const contact = reactive(contactData);

    const contactRules = computed(() => ({
      buyer_name: { required },
      cust_email: {
        email,
        required,
      },
      bill_company: { required },
      bill_ytunnus: { required },
      recv_name: { required },
      recv_street: { required },
      recv_zip: { required },
      recv_town: { required },
      recv_phone: { required },
    }));

    const v$ = useVuelidate(contactRules, contact);

    const goBack = () => {
      router.push({
        name: "Cart",
        params: {
          orderUuid: route.params.orderUuid,
        },
      });
    };

    const submitContact = () => {
      v$.value.$touch();
      if (v$.value.$error) return;

      if (!terms.value) {
        termsError.value = true;
        return;
      } else {
        termsError.value = false;
      }

      const mutation = store.getters.getContactData.id
        ? "putContact"
        : "postContact";

      // set spinner true
      store.dispatch(mutation, contact).then(() => {
        // TEMPORARY: ONLY BILLING ON LAUNCH
        store
          .dispatch("partialUpdateOrder", {
            status: "ORDERED",
          })
          .then(() => {
            //Go to thanks page
            router.push({ name: "ThanksPage" });
          });
        // payment happening here
        // let stripe = Stripe(process.env.VUE_APP_STRIPE_API_KEY); // eslint-disable-line
        // store
        //   .dispatch("createStripeSession")
        //   .then(() => {
        //     return store.state.stripeSessionData.stripe_session_id;
        //   })
        //   .then((session_id) => {
        //     return stripe.redirectToCheckout({ sessionId: session_id });
        //   })
        //   .then(function (result) {
        //     // If `redirectToCheckout` fails due to a browser or network
        //     // error, you should display the localized error message to your
        //     // customer using `error.message`.
        //     if (result.error) {
        //       store.commit("setStripeError", true);
        //       alert(result.error.message);
        //     }
        //   })
        //   .catch(function (error) {
        //     store.commit("setStripeError", true);
        //     console.error("Error:", error);
        //   });
      });
    };

    onBeforeMount(() => {
      // ONLY BILLING ATM, no need to load any stripe
      // let stripeScript = document.createElement("script");
      // stripeScript.setAttribute("src", "https://js.stripe.com/v3/");
      // stripeScript.async = true;
      // stripeScript.ref = "stripe";
      // stripeScript.addEventListener("load", () => {
      //   // Create Stripe instance once Stripe.js loads
      //   stripeLoaded.value = true;
      // });
      // document.head.appendChild(stripeScript);
    });

    return {
      order: computed(() => store.getters.getOrderData),
      goBack,
      submitContact,
      contact,
      stripeLoaded,
      terms,
      termsError,
      v$,
      store,
      sendingType,
      new_fiskar_customer
    };
  },
};
</script>

<style scoped lang="scss">
@import "../assets/sass/mybulma";
.cart-page {

  .go-back-button {
    min-width: 166px;
    background-color: #ffffff;
    color: #0d0d0c;
    border: 2px solid #0d0d0c;
    &:hover {
      background-color: #e9e9e9;
      border-color: #e9e9e9;
    }
  }

  .go-pay-button {
    min-width: 260px;
  }
  .d-icon {
    background: url("../assets/icons/icon_gift.svg");
    height: 22px;
    width: 24px;
    display: block;
  }
}
</style>
